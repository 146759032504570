import {VueSlideToggle} from "vue-slide-toggle";

export default {
  name: "burger-nav",
  components: {
    VueSlideToggle
  },
  props: {
    info: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      open:false,
    }
  },

}