import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter);

const isAuthenticated = (to, from, next) => {
  if (store.state.auth.isAuthenticated) {
    next();
    return
  }
  next('/')
};

// const routes = [
//   {
//     path: '/:lang?',
//     name: 'main',
//     component: () => import('../modules/home/index.vue')
//   },
//   {
//     path: '/:lang?/catalog',
//     name: 'products',
//     component: () => import('../modules/catalog/index.vue')
//   },
//   {
//     path: '/:lang?/:category/card/:slug',
//     name: 'card',
//     component: () => import('../modules/card/index.vue'),
//   },
//
//   {
//     path: '/:lang?/server-not-found',
//     name: 'server-not-found',
//     component: () => import('../modules/system/server-not-found/index.vue')
//   },
//   {
//     path: '*',
//     name: 'page-not-found',
//     component: () => import('../modules/system/page-not-found/index.vue')
//   },
//   {
//     path: '/:lang?/results',
//     name: 'result',
//     component: () => import('../modules/search-catalog/index.vue'),
//   },
//   {
//     path: '/:lang?/card/:slug',
//     name: 'card-redirect',
//     component: () => import('../modules/card/index.vue'),
//   },
//   {
//     path: '/:lang?/buy-flow',
//     name: 'buy-flow',
//     redirect: '/:lang?/buy-flow/basket',
//     component: () => import('../modules/buy-flow/index.vue'),
//     children: [
//       {
//         path: 'basket',
//         name: 'buy-flow-basket',
//         component: () => import('../modules/buy-flow/pages/basket/index.vue'),
//         meta:{
//           title:'Корзина'
//         }
//       },
//       {
//         path: 'order',
//         name: 'buy-flow-order',
//         component: () => import('../modules/buy-flow/pages/order/index.vue'),
//         meta:{
//           title:'Оформлення замовлення'
//         }
//       }
//     ]
//   },
//   {
//     path:'/:lang?/agent-register',
//     name:'agent-register',
//     component:()=> import('../modules/agent-registrations/index.vue')
//   },
//
//   //--------------------------------------pages--------------------------------------
//   {
//     path:'/:lang?/pages/:slug',
//     name:'page',
//     component:()=> import('../modules/pages/page-main/index.vue'),
//   },
//   {
//     path:'/:lang?/pages/:slugPage/:slugIngredient',
//     name:'ingredient-page',
//     component:()=> import('../modules/pages/ingredient-page/index.vue'),
//   },
//   {
//     path:'/:lang?/contacts',
//     name:'contact-page',
//     component:()=> import('../modules/pages/contacts/index.vue'),
//   },
//   //--------------------------------------pages--------------------------------------
//
//   {
//     path:'/:lang?/pages/media',
//     name:'media',
//     component:()=> import('../modules/pages/media/index.vue'),
//     meta:{
//       title:'про нас'
//     }
//   },
//   {
//     path:'/:lang?/faq',
//     name:'faq',
//     component:()=> import('../modules/pages/faq/index.vue'),
//   },
//   {
//     path:'/:lang?/pages/club-professional',
//     name:'club-professional',
//     component:()=> import('../modules/pages/club-professional/index.vue'),
//   },
//   {
//     path:'/:lang?/delivery',
//     name:'delivery',
//     component:()=> import('../modules/pages/delivery-payment/index.vue'),
//   },
//   {
//     path: '/:lang?/profile',
//     redirect: '/:lang?/profile/user-info',
//     component: () => import('../modules/profile/index.vue'),
//     name: 'profile',
//     beforeEnter: isAuthenticated,
//     children: [
//       {
//         path: 'user-info',
//         name: 'profile-user-info',
//         component: () => import('../modules/profile/pages/user-info/index.vue'),
//       },
//       {
//         path: 'user-professional',
//         name: 'profile-user-professional',
//         component: () => import('../modules/profile/pages/user-professional/index.vue'),
//       },
//       {
//         path:'user-current-orders',
//         name:'profile-user-current-orders',
//         component: () => import('../modules/profile/pages/current-orders/index.vue'),
//       },
//       {
//         path:'user-history-orders',
//         name:'profile-user-history-orders',
//         component: () => import('../modules/profile/pages/history-orders/index.vue'),
//       },
//       {
//         path:'user-favorites',
//         name:'profile-user-favorites',
//         component: () => import('../modules/profile/pages/favorites/index.vue'),
//       },
//       {
//         path:'user-professional-club',
//         name:'user-professional-club',
//         redirect:'user-professional-club/clients-list',
//
//         component: () => import('../modules/profile/pages/professional-club/index.vue'),
//         children:[
//           {
//             path:'clients-list',
//             name:'profile-clients-list',
//             component:()=> import('../modules/profile/pages/professional-club/pages/clients-list/index.vue'),
//           },
//           {
//             path:'client-history/:id',
//             name:'profile-client-history',
//             component:()=> import('../modules/profile/pages/professional-club/pages/client-history/index.vue'),
//           },
//         ]
//       }
//     ]
//   },
//
// ];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:[
    {
      path: '/:lang?',
      name: 'main',
      component: () => import('../modules/home/index.vue')
    },
    {
      path: '/:lang?/catalog',
      name: 'products',
      component: () => import('../modules/catalog/index.vue')
    },
    {
      path: '/:lang?/:category/card/:slug',
      name: 'card',
      component: () => import('../modules/card/index.vue'),
    },

    {
      path: '/:lang?/server-not-found',
      name: 'server-not-found',
      component: () => import('../modules/system/server-not-found/index.vue')
    },
    {
      path: '*',
      name: 'page-not-found',
      component: () => import('../modules/system/page-not-found/index.vue')
    },
    {
      path: '/:lang?/results',
      name: 'result',
      component: () => import('../modules/search-catalog/index.vue'),
    },
    {
      path: '/:lang?/card/:slug',
      name: 'card-redirect',
      component: () => import('../modules/card/index.vue'),
    },
    {
      path: '/:lang?/buy-flow',
      name: 'buy-flow',
      redirect: '/:lang?/buy-flow/basket',
      component: () => import('../modules/buy-flow/index.vue'),
      children: [
        {
          path: 'basket',
          name: 'buy-flow-basket',
          component: () => import('../modules/buy-flow/pages/basket/index.vue'),
          meta:{
            title:'Корзина'
          }
        },
        {
          path: 'order',
          name: 'buy-flow-order',
          component: () => import('../modules/buy-flow/pages/order/index.vue'),
          meta:{
            title:'Оформлення замовлення'
          }
        }
      ]
    },
    {
      path:'/:lang?/agent-register',
      name:'agent-register',
      component:()=> import('../modules/agent-registrations/index.vue')
    },

    //--------------------------------------pages--------------------------------------
    {
      path:'/:lang?/pages/:slug',
      name:'page',
      component:()=> import('../modules/pages/page-main/index.vue'),
    },
    {
      path:'/:lang?/pages/:slugPage/:slugIngredient',
      name:'ingredient-page',
      component:()=> import('../modules/pages/ingredient-page/index.vue'),
    },
    {
      path:'/:lang?/contacts',
      name:'contact-page',
      component:()=> import('../modules/pages/contacts/index.vue'),
    },
    //--------------------------------------pages--------------------------------------

    {
      path:'/:lang?/pages/media',
      name:'media',
      component:()=> import('../modules/pages/media/index.vue'),
      meta:{
        title:'про нас'
      }
    },
    {
      path:'/:lang?/faq',
      name:'faq',
      component:()=> import('../modules/pages/faq/index.vue'),
    },
    {
      path:'/:lang?/pages/club-professional',
      name:'club-professional',
      component:()=> import('../modules/pages/club-professional/index.vue'),
    },
    {
      path:'/:lang?/delivery',
      name:'delivery',
      component:()=> import('../modules/pages/delivery-payment/index.vue'),
    },
    {
      path: '/:lang?/profile',
      redirect: '/:lang?/profile/user-info',
      component: () => import('../modules/profile/index.vue'),
      name: 'profile',
      beforeEnter: isAuthenticated,
      children: [
        {
          path: 'user-info',
          name: 'profile-user-info',
          component: () => import('../modules/profile/pages/user-info/index.vue'),
        },
        {
          path: 'user-professional',
          name: 'profile-user-professional',
          component: () => import('../modules/profile/pages/user-professional/index.vue'),
        },
        {
          path:'user-current-orders',
          name:'profile-user-current-orders',
          component: () => import('../modules/profile/pages/current-orders/index.vue'),
        },
        {
          path:'user-history-orders',
          name:'profile-user-history-orders',
          component: () => import('../modules/profile/pages/history-orders/index.vue'),
        },
        {
          path:'user-favorites',
          name:'profile-user-favorites',
          component: () => import('../modules/profile/pages/favorites/index.vue'),
        },
        {
          path:'user-professional-club',
          name:'user-professional-club',
          redirect:'user-professional-club/clients-list',

          component: () => import('../modules/profile/pages/professional-club/index.vue'),
          children:[
            {
              path:'clients-list',
              name:'profile-clients-list',
              component:()=> import('../modules/profile/pages/professional-club/pages/clients-list/index.vue'),
            },
            {
              path:'client-history/:id',
              name:'profile-client-history',
              component:()=> import('../modules/profile/pages/professional-club/pages/client-history/index.vue'),
            },
          ]
        }
      ]
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    let el = document.querySelector('.app');
    if (to.path !== from.path) {
      if (savedPosition) {
        return savedPosition
      } else {
        el.scrollTop = 0;
        return {x: 0, y: 0}
      }
    }
  }
});

export default router
