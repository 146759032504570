import axios from 'axios'
import {$http} from '@/utils/https'
import {
    CHANGE_ADDRESS,
    CHANGE_DIALOG_QUICK_ORDER,
    NOVA_POSHTA_GET_DEPARTMENS_LOADING,
    NOVA_POSHTA_GET_TOWNS_LOADING,
    NOVA_POSHTA_SET_DEPARTMENS,
    NOVA_POSHTA_SET_TOWNS,
    RESET_ADDRESS,
    CHANGE_DELIVERY_TYPE,
    CHANGE_USER_INFO,
    RESET_CHANGE_USER_INFO,
    CHANGE_LOADING,
    CHANGE_SUCCESS_BUY,
    SET_INVOICE,
    CHANGE_INVOICE_LOADING,
    SET_TRANSACTION,
    CHANGE_TRANSACTION_LOADING,
    RESET_PAYMENT,
    SET_ORDER,
    SET_DELIVERY_METHODS,
    SET_PAYMENT_METHODS,
    SET_REPEAT_PRODUCTS,
    SET_DOWNOAD_PDF,
    CHANGE_DOWNLOAD_PDF_LOADING,
    CHANGE_ADDRESS_COURIER,
    GET_CERTIFICATE_LOADING,
    SET_CERTIFICATE,
    CLEAR_CERTIFICATE, SET_ADDRESS_LIST, SAVE_SELECT_ADDRESS

} from '../mutation-types';

import {
    NOVA_POSHTA_GET_DEPARTMENS, NOVA_POSHTA_GET_TOWNS, CREATE_ORDER, CREATE_INVOICE,
    CREATE_TRANSACTION, GET_DELIVERY_METHODS, GET_PAYMENT_METHODS, REPEAT_ORDER,
    DOWNLOAD_PDF, GET_CERTIFICATE, GET_ADDRESS_LIST
} from '../action-types';


const state = {
    savedAddress: '',
    invoiceResult: null,
    isInvoiceLoading: false,
    transactionResult: null,
    isTransactionLoading: false,
    orderResult: null,
    productId: null,
    deliveryMethods: null,
    paymentMethods: null,
    repeatProducts: null,
    productsPdf: null,
    downloadPdfLoading: false,
    certificate: null,
    addresses: [],
    showDialogQuickOrder: false,
    successBuy: false,
    isLoading: false,
    userInfo: {},
    addressCourier: {
        town: 'Киев',
    },
    address: {
        town: 'Киев',
    },
    isDepartmensLoading: false,
    isTownsLoading: false,
    towns: [],
    departments: [],

    deliveryNovaPoshtaData: {},
    deliveryCourierData: {},
    deliveryType: 'couirer'
};

const getters = {
    savedAddress: state => state.savedAddress,
    addresses: state => state.addresses,
    invoiceResult: state => state.invoiceResult,
    isInvoiceLoading: state => state.isInvoiceLoading,
    transactionResult: state => state.transactionResult,
    isTransactionLoading: state => state.isTransactionLoading,
    productId: state => state.productId,
    orderResult: state => state.orderResult,
    deliveryMethods: state => state.deliveryMethods,
    paymentMethods: state => state.paymentMethods,
    repeatProducts: state => state.repeatProducts,
    productsPdf: state => state.productsPdf,
    certificate: state => state.certificate,

    showDialogQuickOrder: state => state.showDialogQuickOrder,
    isLoading: state => state.isLoading,
    address: state => state.address,
    addressCourier: state => state.addressCourier,
    userInfo: state => state.userInfo,
    isDepartmensLoading: state => state.isDepartmensLoading,
    isTownsLoading: state => state.isTownsLoading,
    towns: state => state.towns,
    departments: state => state.departments,

    deliveryNovaPoshtaData: state => state.deliveryNovaPoshtaData,
    deliveryCourierData: state => state.deliveryCourierData,
    deliveryType: state => state.deliveryType,
    successBuy: state => state.successBuy,
    downloadPdfLoading: state => state.downloadPdfLoading,
};

const actions = {
    [GET_ADDRESS_LIST]: async ({commit}) => {
        try {

            const response = await $http.get(`v1/user/addresses`,);
            commit(SET_ADDRESS_LIST, response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {

        }
    }, [GET_CERTIFICATE]: async ({commit}, payload) => {
        try {
            commit(GET_CERTIFICATE_LOADING, true);
            const response = await $http.get(`v1/certificates/${payload}`,);
            commit(SET_CERTIFICATE, response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {
            commit(GET_CERTIFICATE_LOADING, false);
        }
    },
    [DOWNLOAD_PDF]: async ({commit}, payload) => {
        try {
            commit(CHANGE_DOWNLOAD_PDF_LOADING, true);
            const response = await $http.post(`v1/client_prices/download`, payload);
            commit(SET_DOWNOAD_PDF, response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {
            commit(CHANGE_DOWNLOAD_PDF_LOADING, false);
        }
    },
    [REPEAT_ORDER]: async ({commit}, payload) => {
        try {
            // commit(CHANGE_INVOICE_LOADING, true);
            const response = await $http.get(`v1/order/${payload}/repeat`);
            commit(SET_REPEAT_PRODUCTS, response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {
            // commit(CHANGE_INVOICE_LOADING, false);
        }
    },
    [GET_DELIVERY_METHODS]: async ({commit}) => {
        try {
            // commit(CHANGE_INVOICE_LOADING, true);
            const response = await $http.get('v1/delivery_methods');
            commit(SET_DELIVERY_METHODS, response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {
            // commit(CHANGE_INVOICE_LOADING, false);
        }
    },
    [GET_PAYMENT_METHODS]: async ({commit}) => {
        try {
            // commit(CHANGE_INVOICE_LOADING, true);
            const response = await $http.get('v1/payment_methods');
            commit(SET_PAYMENT_METHODS, response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {
            // commit(CHANGE_INVOICE_LOADING, false);
        }
    },
    [CREATE_INVOICE]: async ({commit}, payload) => {
        try {
            commit(CHANGE_INVOICE_LOADING, true);

            const response = await $http.post('v1/invoices', payload);
            commit(SET_INVOICE, response.data.data);
            return response.data.data;
        } catch (e) {
            throw e;
        } finally {
            commit(CHANGE_INVOICE_LOADING, false);
        }
    },
    [CREATE_TRANSACTION]: async ({commit}, payload) => {
        try {
            commit(CHANGE_TRANSACTION_LOADING, true);

            const response = await $http.post('v1/transaction', payload);
            if (response.data?.data?.value) {
                commit(SET_INVOICE, response.data.data);
            }
            commit(SET_TRANSACTION, response.data);
            return response.data;
        } catch (e) {
            throw e;
        } finally {
            commit(CHANGE_TRANSACTION_LOADING, false);
        }
    },

    [CREATE_ORDER]: async ({commit}, payload) => {
        commit(CHANGE_LOADING, true);
        try {
            const response = await $http.post(`v1/order`, payload);
            commit(SET_ORDER, response.data.data);
            return response.data.data
        } catch (e) {
            throw e;
        } finally {
            commit(CHANGE_LOADING, false);
        }
    },
    [NOVA_POSHTA_GET_TOWNS]: async ({commit}, payload) => {
        commit(NOVA_POSHTA_GET_TOWNS_LOADING, true);
        try {
            const response = await axios({
                method: 'POST',
                url: "https://api.novaposhta.ua/v2.0/json/",
                data: payload
            })
            commit(NOVA_POSHTA_SET_TOWNS, response.data.data)
            commit(NOVA_POSHTA_SET_DEPARTMENS, [])
        } catch (e) {
            throw e;
        } finally {
            commit(NOVA_POSHTA_GET_TOWNS_LOADING, false);
        }
    },
    [NOVA_POSHTA_GET_DEPARTMENS]: async ({commit}, payload) => {
        commit(NOVA_POSHTA_GET_DEPARTMENS_LOADING, true);
        try {
            const response = await axios({
                method: 'POST',
                url: "https://api.novaposhta.ua/v2.0/json/",
                data: payload
            })
            commit(NOVA_POSHTA_SET_DEPARTMENS, response.data.data)
        } catch (e) {
            throw e;
        } finally {
            commit(NOVA_POSHTA_GET_DEPARTMENS_LOADING, false);
        }
    },
};

const mutations = {
    [SAVE_SELECT_ADDRESS](state, data) {
        state.savedAddress = data;
    },
    [SET_ADDRESS_LIST](state, data) {
        state.addresses = data;
    },
    [SET_DOWNOAD_PDF](state, data) {
        state.productsPdf = data;
    },
    [SET_REPEAT_PRODUCTS](state, data) {
        state.repeatProducts = data;
    },
    [SET_ORDER](state, data) {
        state.orderResult = data;
    },
    [SET_DELIVERY_METHODS](state, data) {
        state.deliveryMethods = data;
    },
    [SET_PAYMENT_METHODS](state, data) {
        state.paymentMethods = data;
    },

    [SET_CERTIFICATE](state, data) {
        state.certificate = data;
    },
    [CLEAR_CERTIFICATE](state) {
        state.certificate = null;
    },
    [GET_CERTIFICATE_LOADING](state, status) {
        state.certificateLoading = status;
    },

    [SET_TRANSACTION](state, data) {
        state.transactionResult = data;
    },
    [CHANGE_TRANSACTION_LOADING](state, status) {
        state.isTransactionLoading = status;
    },
    [RESET_PAYMENT](state, id) {
        state.productId = null;
        state.orderResult = null;
        state.invoiceResult = null;
        state.transactionResult = null;
    },
    [SET_INVOICE](state, data) {
        state.invoiceResult = data;
    },
    [CHANGE_INVOICE_LOADING](state, status) {
        state.isInvoiceLoading = status;
    },
    [CHANGE_DIALOG_QUICK_ORDER](state, status) {
        state.showDialogQuickOrder = status;
    },
    [CHANGE_LOADING](state, status) {
        state.isLoading = status;
    },
    [CHANGE_ADDRESS](state, data) {
        Object.assign(state.address, data)
    },
    [CHANGE_ADDRESS_COURIER](state, data) {
        state.addressCourier = data
    },
    [CHANGE_USER_INFO](state, data) {
        Object.assign(state.userInfo, data)
    },
    [CHANGE_DELIVERY_TYPE](state, val) {
        state.deliveryType = val;
    },
    [CHANGE_SUCCESS_BUY](state, val) {
        state.successBuy = val;
    },
    [RESET_ADDRESS](state) {
        for (let i in state.address) {
            state.address[i] = ''
        }
    },
    [RESET_CHANGE_USER_INFO](state) {
        for (let i in state.userInfo) {
            state.userInfo[i] = ''
        }
    },
    [NOVA_POSHTA_GET_DEPARTMENS_LOADING](state, status) {
        state.isDepartmensLoading = status
    },
    [NOVA_POSHTA_GET_TOWNS_LOADING](state, status) {
        state.isTownsLoading = status
    },
    [CHANGE_DOWNLOAD_PDF_LOADING](state, status) {
        state.downloadPdfLoading = status
    },
    [NOVA_POSHTA_SET_TOWNS](state, data) {
        state.towns = data
    },
    [NOVA_POSHTA_SET_DEPARTMENS](state, data) {
        state.departments = data
    },
};
export default {
    state,
    getters,
    actions,
    mutations,
};
