import {mapActions, mapGetters, mapMutations} from "vuex";
import navigateTo from '../../mixins/buy-flow'
import _ from "lodash";
import {directive as onClickaway} from 'vue-clickaway';
import burgerNav from './components/burger-nav/index.vue'
import {fixBody} from '@/helpers/variables/index'

export default {
  name: "main-header",
  mixins: [navigateTo],
  directives: {
    onClickaway: onClickaway,
  },
  components: {
    burgerNav
  },

  data() {
    return {
      querySearch: '',
      show: false,
      openBurger: false,
      showSearch: false,
      sortedMenu: [],
      windowWidth: window.innerWidth <= 980
    }
  },
  watch: {
    '$route'() {
      this.openBurger = false
    },
    'openBurger'() {
      if (this.openBurger === true) {
        document.getElementById('html').classList.add('hide')
      } else {
        document.getElementById('html').classList.remove('hide')
      }
    }

    },
  created() {

    this.fetchHeaderMenu().then(() => {
      let submenu = []
      for (let key in this.headerMenu) {

        let menu = this.headerMenu[key]
        this.sortedMenu.push(menu)

      }

      let sortedProducts
      this.sortedMenu.forEach(el => {
        if (el.slug === 'products') {
          for (let i in el.items) {
            submenu.push(el.items[i])
          }

        }
      })
      sortedProducts = submenu.sort((a, b) => a.position > b.position ? 1 : -1);

      this.sortedMenu.forEach(el => {
        if (el.slug === 'products') {
          el.items = sortedProducts
        }
      })

    })
    // window.addEventListener("resize", this.myEventHandler);

  },
  computed: {
    ...mapGetters([
      'languages',
      'currentLanguage',
    ]),
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      basket: 'basket/basket',
      user: 'profile/user',
      isProfessional: 'profile/isProfessional',
      headerMenu: 'setting/headerMenu',
      searchList: 'search/searchList',
      searchLoading: 'search/searchLoading',
      whichList: 'favorites/whichList',
      contacts: 'setting/contacts',

    })
  },
  methods: {
    ...mapMutations({
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP'
    }),
    ...mapActions({
      fetchSearchItems: 'search/GET_SEARCH_LIST',
      fetchHeaderMenu: 'setting/GET_HEADER_MENU',
    }),
    sendRequest: _.debounce(function () {
      if (this.querySearch.length > 2) {
        this.fetchSearchItems(this.querySearch);
      }
    }, 500),

    myEventHandler() {
      if (window.innerWidth <= 980) {
        this.showSearch = !this.showSearch
      }

    },
    setFocusField(status) {
      this.show = status;
    },
    changeSearch() {
      this.sendRequest()
    },
    changeLanguage(code) {
      this.showLangList = false;
      this.$setLanguage(code).then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 900)
      })
    },
    away: function () {
      this.showSearch = false
    },
  },

  destroyed() {
    // window.removeEventListener("resize", this.myEventHandler);
  }
}
