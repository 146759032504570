import {mapGetters} from "vuex";

export default {
  name: "success-register",
  computed: {
    ...mapGetters({
      successRegisterPopup:'popups/successRegisterPopup',
      agentCode:'auth/agentCode',
    })
  }
}