import {mapActions, mapGetters, mapMutations} from "vuex";
import {minLength, required, email} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";

export default {
  name: "form-application",
  data() {
    return {
      payload: {
        name: '',
        address: '',
        phone: ''
      },
      validationErrors: {}
    }
  },
  validations: {
    payload: {
      name: {
        required
      },
      phone: {
        required,
        minLength: minLength(13),
      }
    }
  },
  computed: {
    ...mapGetters({
      requestLoading: 'pages/requestLoading'
    }),
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.phone.minLength) {
        error.push(this.$t('validationMin').replace(':count', 13))
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      sendForm: 'pages/USER_REQUESTS'
    }),
    ...mapMutations({
      changeApplicationFormPopup: `popups/CHANGE_APPLICATION_FORM_POPUP`,
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let obj = {}
        obj.name = this.payload.name
        obj.name_and_address = this.payload.address
        obj.phone = this.payload.phone
        this.sendForm(obj).then(() => {
          this.$toasted.success(this.$t('requestSuccessSend'))
          this.changeApplicationFormPopup(false)
        })
      }
    }
  }
}