import Vue from 'vue';
import {$http} from '@/utils/https';
import {
  ADD_ITEM_TO_BASKET,
  CHECK_PROMO_CODE_LOADING,
  DECREMENT_BASKET_LIST_COUNT,
  INCREMENT_BASKET_LIST_COUNT,
  REMOVE_ITEM_FROM_BASKET,
  RESET_BASKET,
  RESET_DISCOUNT,
  SET_BASKET_EDIT_COUNT,
  SET_BASKET_LIST_COUNT,
  SET_DISCOUNT,
  SET_PROMO_CODE_IN_BASKET, SET_SYNC_BASKET, SET_DEFAULT_PRICE_BASKET
} from '../mutation-types';
import {GET_SYNC_BASKET, GET_DEFAULT_PRICE_BASKET} from '../action-types';

function save() {
  const parsed = JSON.stringify(state.basket);
  localStorage.setItem('products', parsed);
}

function sortByKey(array, key) {
  return array.sort((a, b) => {
    let x = a[key];
    let y = b[key];

    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}

const state = {
  buyLoading: false,
  promoCode: '',
  discount: null,
  loadingPromoCode: false,
  defaultPrices: null,
  secondBasket: [],
  basket: localStorage.getItem('products') ? JSON.parse(localStorage.getItem('products')) : [],
};

const getters = {
  showPopupRemoveItem: state => state.showPopupRemoveItem,
  removeDataItem: state => state.removeDataItem,
  defaultPrices: state => state.defaultPrices,
  secondBasket: state => state.secondBasket,

  promoCode: state => state.promoCode,
  discount: state => state.discount,
  buyLoading: state => state.buyLoading,
  loadingPromoCode: state => state.loadingPromoCode,
  basket: state => state.basket || [],
};

const actions = {
  // [CHECK_PROMO_CODE]: async ({commit}) => {
  //   commit(CHECK_PROMO_CODE_LOADING, true);
  //   commit(RESET_DISCOUNT);
  //   try {
  //     const response = await $http.post(`v1/order/coupon`, {code: state.promoCode});
  //
  //     commit(SET_DISCOUNT, response.data.data.percent);
  //     return response.data;
  //   } catch (e) {
  //     throw e;
  //   } finally {
  //     commit(CHECK_PROMO_CODE_LOADING, false);
  //   }
  // },
  [GET_SYNC_BASKET]: async ({commit}, payload) => {
    commit(CHECK_PROMO_CODE_LOADING, true);
    try {
      const response = await $http.get(`v1/products?include=product_prices,images,recommendations,faqs,feedbacks&ids=${payload.ids}`);
      commit(SET_SYNC_BASKET, response.data.data);
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      commit(CHECK_PROMO_CODE_LOADING, false);
    }
  },
  [GET_DEFAULT_PRICE_BASKET]: async ({commit}, payload) => {
    commit(CHECK_PROMO_CODE_LOADING, true);
    try {
      const response = await $http.get(`v1/products?include=product_prices,images,recommendations,faqs,feedbacks&ids=${payload.ids}&default_price=1`);
      commit(SET_DEFAULT_PRICE_BASKET, response.data.data);
      return response.data;
    } catch (e) {
      throw e;
    } finally {
      commit(CHECK_PROMO_CODE_LOADING, false);
    }
  },
};

const mutations = {
  [ADD_ITEM_TO_BASKET](state, obj) {
    if (!state.basket.length) {

      // obj.select_count = 1
      state.basket.push(obj);
      save();
      return;
    }
    const list = state.basket.filter(e => e.id === obj.id)
    console.log(obj.select_type, 'type');

    if (list.length) {
      if (obj.select_type) {

        const item = state.basket.find(e => e.id === obj.id && e.select_type.id === obj.select_type.id);
        if (item) {
          const index = state.basket.findIndex(e => e.id === obj.id && e.select_type.id === obj.select_type.id);
          obj.select_count = state.basket[index].select_count + 1
          Vue.set(state.basket, index, obj)
        } else {
          // obj.select_count = 1
          state.basket.push(obj);
        }
      } else {
        const item = state.basket.find(e => e.id === obj.id);
        if (item) {
          const index = state.basket.findIndex(e => e.id === obj.id);
          obj.select_count = state.basket[index].select_count + 1
          Vue.set(state.basket, index, obj)
        } else {
          // obj.select_count = 1
          state.basket.push(obj);
        }
      }

    } else {
      // obj.select_count = 1
      state.basket.push(obj);
    }
    save();
  },
  [REMOVE_ITEM_FROM_BASKET](state, obj) {
    let index
    if (obj.select_type) {
      index = state.basket.findIndex(e => e.id === obj.id && e.select_type.id === obj.select_type.id);
    } else {
      index = state.basket.findIndex(e => e.id === obj.id);
    }

    Vue.delete(state.basket, index)
    save();
  },
  [RESET_BASKET](state) {
    localStorage.removeItem('products');
    state.basket = [];

  },
  [CHECK_PROMO_CODE_LOADING](state, status) {
    state.loadingPromoCode = status;
  },
  [INCREMENT_BASKET_LIST_COUNT](state, obj) {
    let index
    if (obj.select_type) {
      index = state.basket.findIndex(e => e.id === obj.id && e.select_type.id === obj.select_type.id);

    } else {
      index = state.basket.findIndex(e => e.id === obj.id);
    }
    obj.select_count = obj.select_count + 1;
    Vue.set(state.basket, index, obj);
    save();
  },
  [SET_BASKET_LIST_COUNT](state, obj) {
    let index
    if (obj.select_type) {
      index = state.basket.findIndex(e => e.id === obj.id && e.select_type.id === obj.select_type.id);
    } else {
      index = state.basket.findIndex(e => e.id === obj.id);
    }
    Vue.set(state.basket, index, obj);
    save();
  },
  [DECREMENT_BASKET_LIST_COUNT](state, obj) {
    let index
    if (obj.select_type) {
      index = state.basket.findIndex(e => e.id === obj.id && e.select_type.id === obj.select_type.id);
    } else {
      index = state.basket.findIndex(e => e.id === obj.id);
    }
    obj.select_count = obj.select_count - 1;
    Vue.set(state.basket, index, obj);
    save();
  },
  [SET_BASKET_EDIT_COUNT](state, val) {
    state.editCount = val;
  },
  [SET_PROMO_CODE_IN_BASKET](state, code) {
    state.promoCode = code;
  },
  [SET_DISCOUNT](state, discount) {
    state.discount = discount;
  },
  [RESET_DISCOUNT](state) {
    state.discount = null;
  },
  [SET_DEFAULT_PRICE_BASKET](state, data) {
    // state.defaultPrices = list;
    state.secondBasket = []
    state.secondBasket = state.basket
    let basket = state.secondBasket.sort((a, b) => {
      let x = a['id'];
      let y = b['id'];

      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
    let list = data.sort((a, b) => {
      let x = a['id'];
      let y = b['id'];

      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
    for (let i in basket) {
      for (let j in list) {
        if (basket[i].id === list[j].id) {
          for (let l in list[j].productPrices.data) {
            if ((basket[i].select_type.value === list[j].productPrices.data[l].value)) {
              basket[i].select_type.defaultPrice = list[j].productPrices.data[l].price
              // save()
            }
          }
        }
      }
    }
    state.secondBasket = basket
  },
  [SET_SYNC_BASKET](state, data) {
    for (let i in state.basket) {
      for (let j in data) {
        if ((state.basket[i].id === data[j].id) && state.basket[i].productPrices.data.length && data[j].productPrices.data.length) {
          for (let l in data[j].productPrices.data) {
            if ((state.basket[i].select_type.value === data[j].productPrices.data[l].value)
              && (state.basket[i].select_type.price !== data[j].productPrices.data[l].price)) {
              state.basket[i].select_type.price = data[j].productPrices.data[l].price
              save()
            }
          }
        } else if (state.basket[i].id === data[j].id) {
          if (state.basket[i].price !== data[j].price) {
            state.basket[i].price = data[j].price
            save()
          }
        }
      }
    }
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
