import {mapGetters, mapMutations} from 'vuex';
import login from '../popups/login/index.vue'
import registration from '../popups/registration/index.vue'
import reset from '../popups/reset/index.vue'
import restore from '../popups/restore/index.vue'
import addressesList from '../popups/addresses-list/index.vue'
import instagramPost from '../popups/instagram-post/index.vue'
import successRegister from '../popups/success-register/index.vue'
import addAddress from '../popups/add-address/index.vue'
import anotherCosmetolog from '../popups/activate-another-cosmetolog/index.vue'
import fastBuy from '../popups/fast-buy/index.vue'
import defaultPrices from '../popups/default-prices/index.vue'
import formApplication from '../popups/form-application/index.vue'
import paymentInfo from '../popups/payment-info/index.vue'
import successRegisterAgent from "../popups/successRegisterAgent/index.vue";

export default {
  name: 'hidden',
  components: {
    login,
    registration,
    reset,
    restore,
    addressesList,
    instagramPost,
    successRegister,
    addAddress,
    anotherCosmetolog,
    fastBuy,
    defaultPrices,
    formApplication,
    paymentInfo,
    successRegisterAgent
  },
  computed: {
    ...mapGetters({
      loginPopup: 'popups/loginPopup',
      registrationPopup: 'popups/registrationPopup',
      resetPopup: 'popups/resetPopup',
      restorePopup: 'popups/restorePopup',
      addressesListPopup: 'popups/addressesListPopup',
      instagramPopup: 'popups/instagramPopup',
      successRegisterPopup: 'popups/successRegisterPopup',
      addAddressPopup: 'popups/addAddressPopup',
      anotherCosmetolog: 'popups/anotherCosmetolog',
      fastBuyPopup: 'popups/fastBuyPopup',
      showDefaultPrice: 'popups/showDefaultPrice',
      formApplicationPopup: 'popups/formApplicationPopup',
      paymentInfoPopup: 'popups/paymentInfoPopup',
      isRegisterAgent: 'popups/successRegisterAgent'
    })
  },
  methods: {
    ...mapMutations({
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP',
      changeRegistrationPopup: 'popups/CHANGE_REGISTRATION_POPUP',
      changeResetPopup: 'popups/CHANGE_RESET_POPUP',
      changeRestorePopup: 'popups/CHANGE_RESTORE_POPUP',
      changeAddressesListPopup: 'popups/CHANGE_ADDRESSES_LIST_POPUP',
      changeInstagramPostPopup: 'popups/CHANGE_INSTAGRAM_POPUP',
      changeSuccessRegisterPopup: 'popups/CHANGE_SUCCESS_REGISTER_POPUP',
      changeAddAddressPopup: `popups/CHANGE_ADD_ADDRESS_POPUP`,
      changeAnotherCosmetologPopup: `popups/CHANGE_COSMETOLOG_POPUP`,
      changeFastBuyPopup: `popups/CHANGE_FAST_BUY_POPUP`,
      changeDefaultPricesPopup: `popups/CHANGE_DEFAULT_PRICES`,
      changeApplicationFormPopup: `popups/CHANGE_APPLICATION_FORM_POPUP`,
      changePaymentInfoPopup: 'popups/CHANGE_PAYMENT_INFO_POPUP',
      changeRegisterAgent: 'popups/CHANGE_SHOW_SUCCESS_AGENT_REGISTER'
    })
  }
}
