var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container",class:{'container-crumbs':_vm.$route.query.title==='О нас'|| _vm.$route.query.title==='Про нас'}},[(_vm.translations && _vm.list)?_c('div',{staticClass:"breadcrumbsCustom"},[_c('div',{staticClass:"breadcrumbsCustom-wrap"},[_c('div',{staticClass:"breadcrumbsCustom-list"},[_c('div',{staticClass:"breadcrumbsCustom-item"},[_c('router-link',{attrs:{"to":_vm.$localize({ name: 'main' })}},[_vm._v(" "+_vm._s(_vm.$t('home'))+" ")])],1),(!_vm.$route.name.includes('profile') && !_vm.$route.path.includes('pages') && !_vm.$route.path.includes('buy-flow') && !_vm.$route.path.includes('contacts')&& !_vm.$route.path.includes('agent-register'))?_c('div',{staticClass:"breadcrumbsCustom-item"},[_c('router-link',{attrs:{"to":_vm.$localize({ name: 'products' })}},[_vm._v(" "+_vm._s(_vm.$t('catalog'))+" ")])],1):_vm._e(),_vm._l((_vm.sortedList),function(item,index){return _c('div',{key:index,staticClass:"breadcrumbsCustom-item"},[(_vm.$route.query.type === 'new')?_c('router-link',{attrs:{"to":_vm.$localize({
                                   name: 'page',
                                   params:{slug:'scientific-news'},
                                   query:{title:_vm.$route.query.title}
                               })}},[_vm._v(" "+_vm._s(item.title)+" ")]):(_vm.$route.name === 'ingredient-page')?_c('router-link',{staticClass:"ingredientCrumb",attrs:{"to":_vm.$localize({
                                   name: 'page',
                                   params:{slug:item.params},
                                   query: {title:item.query}
                               })}},[_vm._v(" "+_vm._s(item.title)+" ")]):(_vm.$route.name === 'page')?_c('a',[_vm._v(" "+_vm._s(item.title)+" ")]):_c('router-link',{attrs:{"to":_vm.$localize({
                                   name: item.slug,
                               })}},[_vm._v(" "+_vm._s(item.title)+" ")])],1)})],2)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }