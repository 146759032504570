import {mapGetters} from "vuex";
import navLi from '../header/components/burger-nav/index.vue'
export default {
  name: "main-footer",
  components:{
    navLi
  },
  computed:{
    ...mapGetters({
      contacts:'setting/contacts',
      headerMenu: 'setting/headerMenu',
    }),
    headerLinks(){
      this.headerMenu.forEach(el=>{
       let arr =[]

      })
    },
    headerList(){
      let arr = []
      let subLinks = []
      for (let i in this.headerMenu){
        arr.push(this.headerMenu[i])

      }
      return arr
    },

    headerSmall(){
      let arr = []
      for (let i in this.headerMenu){
        if(i > '4'){
          arr.push(this.headerMenu[i])
        }
      }
      return arr
    }
  },
  methods:{
    scrollToTop(){
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
  }

}
