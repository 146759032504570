import {mapActions, mapGetters, mapMutations} from "vuex";
import {ModelListSelect} from "vue-search-select";

export default {
    name: "addresses-list",
    data() {
        return {
            payload: {
                address: ''
            },
            searchCountryTitle: '',
            addressList: []
        }
    },
    components: {
        ModelListSelect
    },
    computed: {
        ...mapGetters({
            user: 'profile/user',
            address: 'order/addresses'
        })
    },

    methods: {
        ...mapActions({
            makeMainAddress: 'profile/ADDRESS_MAIN',
            fetchUser: 'profile/FETCH_USER_DATA',
            fetchAddress: 'order/GET_ADDRESS_LIST',
        }),
        ...mapMutations({
            changeAddressesListPopup: 'popups/CHANGE_ADDRESSES_LIST_POPUP',
            changeAddAddressPopup: `popups/CHANGE_ADD_ADDRESS_POPUP`,
            saveAddress: 'order/SAVE_SELECT_ADDRESS'
        }),
        printSearchText(searchText) {
            this.searchCountryTitle = searchText
        },
        selectAddress() {
            this.changeAddAddressPopup(false);
            this.changeAddressesListPopup(false)
            this.payload.status = 0
            this.saveAddress(this.payload)
        },
        enterAddress() {
            this.changeAddAddressPopup(true);
            this.changeAddressesListPopup(false)
            this.payload.status = 1
            this.saveAddress(this.payload)
        },
        changeCurrentAddress(address) {
            if (!address.isMain) {
                this.makeMainAddress(address.id).then(() => {
                    this.fetchUser()
                    this.$toasted.success(this.$t('successChangeAddress'));
                    this.changeAddressesListPopup(false)
                })
            }
        },

    },
    created() {
        this.addressList = this.address

    }
}
