//instagram
export const GET_CODE='GET_CODE'


//auth
export const GET_TOKEN = 'GET_TOKEN';
export const LOGOUT = 'LOGOUT';
export const REGISTRATION = 'REGISTRATION';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SING_IN_WITH_GOOGLE = 'SING_IN_WITH_GOOGLE';
export const SING_IN_WITH_FACEBOOK = 'SING_IN_WITH_FACEBOOK';
export const GET_COUNTRIES = 'GET_COUNTRIES'
//setting
export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_CATEGORIES_MENU = 'GET_CATEGORIES_MENU';
export const GET_CATEGORY_FILTERS = 'GET_CATEGORY_FILTERS';
export const GET_VARIABLES = 'GET_VARIABLES';


//header
export const GET_HEADER_MENU = 'GET_HEADER_MENU';
export const GET_INGREDIENT = 'GET_INGREDIENT';
export const GET_NEWS = 'GET_NEWS';
export const GET_NEW = 'GET_NEW';
export const BEFORE_AFTER = 'BEFORE_AFTER';
export const USER_REQUESTS = 'USER_REQUESTS';
export const COOPERATION_TERMS = 'COOPERATION_TERMS';
export const GET_ONE_TERM = 'GET_ONE_TERM';

export const GET_DEFAULT_PRICE_BASKET = 'GET_DEFAULT_PRICE_BASKET';
//search
//contacts
export const GET_CONTACTS = 'GET_CONTACTS';
export const REPEAT_ORDER = 'REPEAT_ORDER';
export const GET_SEARCH_LIST = 'GET_SEARCH_LIST';
//faq
//home
export const GET_BANNERS = 'GET_BANNERS';
export const GET_NEW_PRODUCTS = 'GET_NEW_PRODUCTS';
export const GET_BESTSELLER = 'GET_BESTSELLER';
export const GET_INSTAGRAM_POSTERS = 'GET_INSTAGRAM_POSTERS';
//page
export const GET_PAGE = 'GET_PAGE';
export const GET_MEDIA = 'GET_MEDIA';
export const GET_FAQ = 'GET_FAQ';
//config
//products
export const GET_FILTER_PRODUCT = 'GET_FILTER_PRODUCT';
//requisites
//basket
export const GET_SYNC_BASKET = 'GET_SYNC_BASKET';
//props selection
//category
export const GET_LIST = 'GET_LIST';
export const GET_LIST_CATEGORY = 'GET_LIST_CATEGORY';
//card
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_SAME_PRODUCTS = 'GET_SAME_PRODUCTS';
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const SEND_QUESTION = 'SEND_QUESTION';

//personal
//setting
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
//profile
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const LINK_WITH_GOOGLE = 'LINK_WITH_GOOGLE';
export const LINK_WITH_FACEBOOK = 'LINK_WITH_FACEBOOK';
export const UNLINK_WITH_GOOGLE = 'UNLINK_WITH_GOOGLE';
export const UNLINK_WITH_FACEBOOK = 'UNLINK_WITH_FACEBOOK';
export const GET_CURRENT_ORDERS = 'GET_CURRENT_ORDERS';
export const GET_HISTORY_ORDERS = 'GET_HISTORY_ORDERS';
export const GET_CLIENT_ORDER = 'GET_CLIENT_ORDER';
export const PROFESSIONAL_ADD_FILES = 'PROFESSIONAL_ADD_FILES';
//orders
export const GET_DELIVERY_METHODS = 'GET_DELIVERY_METHODS';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const DOWNLOAD_PDF = 'DOWNLOAD_PDF';
export const GET_CERTIFICATE = 'GET_CERTIFICATE';
export const GET_SAME_PRODUCT = 'GET_SAME_PRODUCT';
export const GET_ADDRESS_LIST = 'GET_ADDRESS_LIST'
//history
//favorites
export const GET_FAVORITES_LIST = 'GET_FAVORITES_LIST';
export const ADD_PRODUCT_TO_FAVOURITES = 'ADD_PRODUCT_TO_FAVOURITES';
export const REMOVE_PRODUCT_IN_FAVORITE = 'REMOVE_PRODUCT_IN_FAVORITE';

export const GET_FILTERS = 'GET_FILTERS';
export const GET_ITEM = 'GET_ITEM';
export const CREATE_ADDRESS = 'CREATE_ADDRESS';
export const ADDRESS_MAIN = 'ADDRESS_MAIN';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const QUICK_ORDER = 'QUICK_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';

export const NOVA_POSHTA_GET_TOWNS = 'NOVA_POSHTA_GET_TOWNS';
export const NOVA_POSHTA_GET_DEPARTMENS = 'NOVA_POSHTA_GET_DEPARTMENS';
