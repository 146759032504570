import {$http} from '@/utils/https'

import {
  CHANGE_LOADING_LIST,
  SET_FILTERS,
  SET_LIST,
  CHANGE_LIMIT,
  SET_LIST_LENGTH,
  CHANGE_LOADING_FILTERS,
  RESET_LIMIT
} from '../mutation-types';
import {GET_LIST, GET_FILTERS, GET_FILTER_PRODUCT} from '../action-types';

const state = {
  list: [],
  loadingList: false,
  filters: null,
  loadingFilters: false,
  limit: 6,
  skip: 0,
  hidePagination: false,
}
const getters = {
  list: state => state.list,
  filters: state => state.filters,
  loadingList: state => state.loadingList,
  loadingFilters: state => state.loadingFilters,
  limit: state => state.limit,
  skip: state => state.skip,
  hidePagination: state => state.hidePagination,
}
const actions = {
  [GET_LIST]: async ({commit}, payload) => {
    commit(CHANGE_LOADING_LIST, true);
    try {
      const response = await $http.get(`v1/products?skip=${state.skip}&take=${state.limit}&include=categories,images,product_prices,unit,recommendations,faqs,feedbacks&ids=${payload ? payload.ids : ''}`);

      commit(SET_LIST, response.data.data);
      // commit(SET_LIST_LENGTH, response.data?.meta?.total || 0);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_LIST, false);
    }
  },
  [GET_FILTERS]: async ({commit}) => {
    commit(CHANGE_LOADING_FILTERS, true);
    try {
      const response = await $http.get(`v1/filter`);

      commit(SET_FILTERS, response.data.data);
      // commit(SET_LIST_LENGTH, response.data?.meta?.total || 0);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_FILTERS, false);
    }
  },
  [GET_FILTER_PRODUCT]: async ({commit}, payload) => {
    console.log('payload', payload);
    commit(CHANGE_LOADING_LIST, true);
    try {
      const response = await $http.post(`v1/filter${payload.data.slug ? '/' + payload.data.slug : ''}?filter_type=${payload.data.filter_type ? payload.data.filter_type : ''}&skip=${state.skip}&take=${state.limit}&include=categories,images,product_prices,unit,recommendations,faqs,feedbacks`);
      commit(SET_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_LIST, false);
    }
  },
}
const mutations = {
  [SET_LIST](state, list) {
    console.log(list)
    let test = list.map(e => {
      e.selected = false
      return e
    })
    if(state.limit > list.length){
      state.hidePagination = true
    }
    // if (state.list.length) {
    //   state.list = state.list.concat(test);
    // } else {
      state.list = test
    // }

  },
  [SET_FILTERS](state, list) {
    state.filters = list;
  },
  [CHANGE_LOADING_LIST](state, status) {
    state.loadingList = status
  },
  [CHANGE_LOADING_FILTERS](state, status) {
    state.loadingFilters = status
  },
  [CHANGE_LIMIT](state, take) {
    state.limit += take
  },
  [RESET_LIMIT](state) {
    state.limit = 6
  }
}

export default {
  state, getters, actions, mutations
}