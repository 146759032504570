import {mapActions, mapGetters, mapMutations} from "vuex";
import axios from "axios";
import defaultPrice from '../../../modules/buy-flow/pages/basket/components/default-price/index.vue'
export default {
  name: "default-prices",
  components:{
    defaultPrice
  },
  data() {
    return {}
  },
  created() {
  },
  computed: {
    ...mapGetters({
      productsPdf: 'order/productsPdf',
      basket:'basket/basket',
      downloadPdfLoading:'order/downloadPdfLoading',
      user: 'profile/user',
      secondBasket: 'basket/secondBasket',
      isProfessional: 'profile/isProfessional',
    }),
    totalSum() {
      return this.basket.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.select_type.defaultPrice * currentValue.select_count);
      }, 0);
    },
    discount() {
      if (this.user && this.user.temp_discount) {
        return (this.totalSum * 5) / 100
      }
      let sum
      if (this.user && this.user.professional.data && this.user.professional.data.active || this.isProfessional) {
        sum = this.secondBasket.reduce((accumulator, currentValue) => {
          if (currentValue.select_type.defaultPrice) {
            return accumulator + (currentValue.select_type.defaultPrice * currentValue.select_count)
          } else {
            return accumulator + (currentValue.select_type.price * currentValue.select_count)
          }
        }, 0)
        return sum - this.totalSum
      } else {
        return
      }
    },
    totalSumClient(){
      return this.basket.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.select_type.defaultPrice * currentValue.select_count);
      }, 0);
    },
    sumDiscount() {
      return this.secondBasket.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.select_type.defaultPrice * currentValue.select_count);
      }, 0)
    }
  },
  methods: {
    ...mapActions({
      fetchPdf:`order/DOWNLOAD_PDF`,
    }),
    ...mapMutations({
      increment: 'basket/INCREMENT_BASKET_LIST_COUNT',
      decrement: 'basket/DECREMENT_BASKET_LIST_COUNT',
      setCount: 'basket/SET_BASKET_LIST_COUNT',
      remove: 'basket/REMOVE_ITEM_FROM_BASKET',
    }),
    async convertPdf(){
      const response = await axios.get(this.productsPdf.path, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = 'example.pdf';
      link.click();
      URL.revokeObjectURL(link.href);
    },
    downloadPdf(){
      let obj = {
        products:[]
      }
      for(let i in this.basket){
        if (this.basket[i].select_type) {
          obj.products.push({
            id: this.basket[i].id,
            value: this.basket[i].select_type.value,
            count: this.basket[i].select_count
          })
        }
      }
      this.fetchPdf(obj).then(()=>{
        this.convertPdf()
      })
    },
    changeCount(type, item, count = 1,) {
      if (type === 'increment') {
        this.increment(item)
      } else if (type === 'decrement') {
        this.decrement(item)
      } else {
        if (count) {
          this.setCount({
            ...item,
            select_count: parseInt(count)
          })
        }
      }
    },
  }
}
