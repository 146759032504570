import {$http} from '@/utils/https';
import axios from "axios";
// import {globalLoader, fixBody} from '@/helpers/variables/index';
import {
  SET_TOKEN,
  REMOVE_TOKEN,
  CHANGE_POPUP_RESTORE_PASSWORD,
  CHANGE_POPUP_NEW_PASSWORD,
  SET_LOADING_REGISTRATION,
  CHANGE_POPUP_LDAP,
  SING_IN_WITH_LDAP_LOADING,
  CHECK_EMAIL_LOADING,
  CHANGE_LOADING_SING_IN,
  CHANGE_LOADING, CHANGE_SUCCESS_REGISTER_POPUP,
  SET_AGENT_CODE, SET_COUNTRIES
} from '../mutation-types';
import {
  GET_TOKEN,
  REGISTRATION,
  LOGOUT,
  RESET_PASSWORD,
  SET_NEW_PASSWORD,
  SING_IN_WITH_GOOGLE,
  SING_IN_WITH_FACEBOOK, GET_COUNTRIES,
} from '../action-types';
import popups from "./popups";

const state = {
  isAuthenticated: false,

  showRestorePassword: false,
  showSetNewPassword: false,
  loadingRegistration: false,
  loadingSingIn: false,
  popupLdapWarning: false,
  checkEmailLoading: false,
  isLoading: false,
  agentCode: null,
};

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  checkEmailLoading: state => state.checkEmailLoading,
  showRestorePassword: state => state.showRestorePassword,
  showSetNewPassword: state => state.showSetNewPassword,
  singIn: state => state.singIn,
  loadingRegistration: state => state.loadingRegistration,
  loadingSingIn: state => state.loadingSingIn,
  isLoading: state => state.isLoading,
  popupLdapWarning: state => state.popupLdapWarning,
  agentCode: state => state.agentCode,

};

const actions = {
  [GET_COUNTRIES]: async ({commit}) => {
    // commit(CHANGE_LOADING_SING_IN, true);
    try {
      const axios = require("axios");

      const options = {
        method: 'GET',
        url: `https://wft-geo-db.p.rapidapi.com/v1/geo/countries`,
        params: {limit: '10'},
        headers: {
          'X-RapidAPI-Host': 'wft-geo-db.p.rapidapi.com',
          'X-RapidAPI-Key': 'e9fcccba0bmshf5f52161a5b7937p140b31jsn13e1efe61946'
        }
      };

      axios.request(options).then(function (response) {
        // commit(SET_COUNTRIES,response.data);
        console.log(response.data);
      }).catch(function (error) {
        console.error(error);
      });
    } catch (e) {
      throw e;
    } finally {
      // commit(CHANGE_LOADING_SING_IN, false)
    }
  },
  [GET_TOKEN]: async ({commit}, payload) => {
    commit(CHANGE_LOADING_SING_IN, true);
    try {
      const result = await $http.post('v1/auth/login', payload);
      commit(SET_TOKEN, result.data.data.accessToken);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_SING_IN, false)
    }
  },
  [SING_IN_WITH_GOOGLE]: async ({commit}, payload) => {
    commit(CHANGE_LOADING_SING_IN, true);
    try {
      const result = await $http.post('v1/auth/social_login', payload);
      commit(SET_TOKEN, result.data.accessToken);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_SING_IN, false);
    }
  },
  [SING_IN_WITH_FACEBOOK]: async ({commit}, payload) => {
    commit(CHANGE_LOADING_SING_IN, true);
    try {
      const result = await $http.post('v1/auth/social_login', payload);
      commit(SET_TOKEN, result.data.accessToken);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_SING_IN, false);
    }
  },
  [LOGOUT]: async ({commit}) => {
    try {

      return await $http.post('v1/logout');
    } catch (e) {
      throw e;
    } finally {
      commit(REMOVE_TOKEN);
    }
  },
  [REGISTRATION]: async ({commit}, payload) => {
    try {
      commit(SET_LOADING_REGISTRATION, true);

      const result = await $http.post('v1/auth/register?include=code', payload);
      console.log(result);
      if (result.data.data.code) {
        if (payload.professional_id) {
          commit(SET_TOKEN, result.data.data.accessToken);
        }
        commit(SET_AGENT_CODE, result.data.data.code)
        setTimeout(() => {
          commit('popups/CHANGE_SUCCESS_REGISTER_POPUP', {percent: result.data.data.percent}, {root: true})
        }, 500)
        return result.data.data.code


      }
      if (result.data.data.accessToken) {
        commit(SET_TOKEN, result.data.data.accessToken);
        setTimeout(() => {
          commit('popups/CHANGE_SUCCESS_REGISTER_POPUP', {percent: result.data.data.percent}, {root: true})
        }, 500)

      }
    } catch (e) {
      throw e;
    } finally {
      commit(SET_LOADING_REGISTRATION, false);
    }
  },
  [RESET_PASSWORD]: async ({commit}, payload) => {
    commit(CHANGE_LOADING, true)
    try {

      return await $http.post('v1/auth/reset', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [SET_NEW_PASSWORD]: async ({commit}, payload) => {
    commit(CHANGE_LOADING, true)
    try {
      const result = await $http.post('v1/auth/restore', payload);
      commit(SET_TOKEN, result.data.data.accessToken);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
};

const mutations = {
  [SET_TOKEN](state, token) {
    state.isAuthenticated = true;
    localStorage.setItem('user_token', token);
  },
  [REMOVE_TOKEN](state) {
    localStorage.removeItem('user_token');
    state.isAuthenticated = false;
    setTimeout(() => {
      location.reload()
    }, 200)
  },
  [CHANGE_POPUP_RESTORE_PASSWORD](state, status) {
    state.showRestorePassword = status;
    // fixBody(status)
  },
  [CHECK_EMAIL_LOADING](state, status) {
    state.checkEmailLoading = status;
  },
  [SET_LOADING_REGISTRATION](state, status) {
    state.loadingRegistration = status;
  },
  [CHANGE_LOADING](state, status) {
    state.isLoading = status;
  },
  [CHANGE_LOADING_SING_IN](state, status) {
    state.loadingSingIn = status;
  },
  [CHANGE_POPUP_NEW_PASSWORD](state, status) {
    state.showSetNewPassword = status;
    // fixBody(status)
  },
  [CHANGE_POPUP_LDAP](state, status) {
    state.showPopupLdap = status;
    // fixBody(status)

  },
  [SING_IN_WITH_LDAP_LOADING](state, status) {
    state.singInLdapLoading = status;
  },
  [SET_AGENT_CODE](state, data) {
    state.agentCode = data;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
