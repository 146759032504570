import Vue from 'vue';
import store from '@/store';
import router from '@/router';
import axios from 'axios';
import VueAxios from 'vue-axios';

const camelcaseObjectDeep = require('camelcase-object-deep');

Vue.use(VueAxios, axios);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

export const $http = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('user_token')}`,
    'Accept': 'application/json',
    'Access-Control-Allow-Headers' : "*"
  }
});
$http.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('user_token')}`;
  config.headers['X-localization'] = store.getters.currentLanguage.translationKey;
  return config;
});

$http.interceptors.response.use((response) => {
    response.data = camelcaseObjectDeep(response.data);
    return response;
  }, (error) => {

    const originalRequest = error.config;
    switch (error.response.status) {
      case 500: {
        router.push({name: 'server-not-found'}).catch(() => {
          console.log()
        });
        break;
      }
      case 404: {
        router.push({name: 'page-not-found'}).catch(() => {
          console.log()
        });
        break;
      }
      case 401: {
        // router.push({name: 'sing-in'}).catch(() => {
        //   console.log()
        //
        // });
        store.commit('auth/REMOVE_TOKEN');
        store.commit('popups/CHANGE_LOGIN_POPUP', true)
        break;
      }
      default: {
        break;
      }
    }
    if (error.response.status === 401 && !originalRequest.retry) {
      if (isRefreshing) {
        return new Promise(((resolve, reject) => {
          failedQueue.push({resolve, reject});
        })).then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return Vue.axios(originalRequest);
        }).catch(error => error);
      }

      originalRequest.retry = true;
      isRefreshing = true;

      return new Promise(((resolve, reject) => {
        $http.post('v1/auth/refresh', {token: localStorage.getItem('user_token')})
          .then(response => {
            store.commit(`auth/SET_TOKEN`, response.data.data.accessToken);
            originalRequest.headers.Authorization = `Bearer ${response.data.data.accessToken}`;
            processQueue(null, response.data.data.accessToken);
            resolve(Vue.axios(originalRequest));
          })
          .catch((error) => {
            console.log(error);
            router.push({name: 'main'}).catch(() => {
              console.log()
            });
            store.commit(`auth/REMOVE_TOKEN`);
            processQueue(error, null);
            reject(error);
          })
          .then(() => {
            isRefreshing = false;
          });
      }));
    }
    return Promise.reject(camelcaseObjectDeep(error.response));
  }
);

export const $httpInstagram = axios.create({
  baseURL: 'https://api.instagram.com/oauth/',

});

export default function install(Vue) {
  Object.defineProperty(Vue.prototype, '$http', {
    get() {
      return $http
    }
  })
}
