//utils
import './utils/plugins'
import './utils/translations'
import './utils/global-components'
import VueTheMask from 'vue-the-mask';
import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.use(VueSmoothScroll, {
    duration: 400,
    offset: -380,
})
//
import moment from 'moment';

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueHead from 'vue-head'
// moment
Vue.prototype.moment = moment
Vue.use(VueTheMask)

Vue.use(VueHead)
//vue configs
Vue.config.productionTip = false;

//set token after refresh page
if (localStorage.getItem('user_token')) {
    store.commit(`auth/SET_TOKEN`, localStorage.getItem('user_token'));
}

const app = new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    }
});

const eventHub = new Vue()
Vue.mixin({
    data: function () {
        return {
            eventHub: eventHub
        }
    }
})


Vue.initI18nManager().then(() => {
    app.$mount('#app')
});

