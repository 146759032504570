// import {$http} from '@/utils/https'
// import {translation} from '@/helpers/translation'
// import {globalLoader} from '@/helpers/variables/index'
import {
  CHANGE_LOGIN_POPUP,
  CHANGE_REGISTRATION_POPUP,
  CHANGE_RESET_POPUP,
  CHANGE_RESTORE_POPUP,
  CHANGE_ADDRESSES_LIST_POPUP,
  CHANGE_INSTAGRAM_POPUP,
  CHANGE_SUCCESS_REGISTER_POPUP,
  CHANGE_ADD_ADDRESS_POPUP,
  CHANGE_COSMETOLOG_POPUP,
  CHANGE_FAST_BUY_POPUP,
  CHANGE_DEFAULT_PRICES,
  CHANGE_APPLICATION_FORM_POPUP,
  CHANGE_PAYMENT_INFO_POPUP, CHANGE_SHOW_SUCCESS_AGENT_REGISTER

} from '../mutation-types';

import {} from '../action-types';

const state = {
  loginPopup: false,
  registrationPopup: false,
  resetPopup: false,
  restorePopup: false,
  addressesListPopup: false,
  instagramPopup: false,
  showDefaultPrice: false,
  formApplicationPopup: false,
  paymentInfoPopup: false,
  successRegisterPopup: {
    show: false,
    text: '',
    code: '',
    percent: null
  },
  addAddressPopup: false,
  anotherCosmetolog: false,
  fastBuyPopup: {
    show: false,
    product: null,
  },
  successRegisterAgent: false
};

const getters = {
  loginPopup: state => state.loginPopup,
  registrationPopup: state => state.registrationPopup,
  resetPopup: state => state.resetPopup,
  restorePopup: state => state.restorePopup,
  addressesListPopup: state => state.addressesListPopup,
  instagramPopup: state => state.instagramPopup,
  successRegisterPopup: state => state.successRegisterPopup,
  addAddressPopup: state => state.addAddressPopup,
  anotherCosmetolog: state => state.anotherCosmetolog,
  fastBuyPopup: state => state.fastBuyPopup,
  showDefaultPrice: state => state.showDefaultPrice,
  formApplicationPopup: state => state.formApplicationPopup,
  paymentInfoPopup: state => state.paymentInfoPopup,
  successRegisterAgent: state => state.successRegisterAgent
};

const actions = {};

const mutations = {
  [CHANGE_DEFAULT_PRICES](state, status) {
    state.showDefaultPrice = status;
  },
  [CHANGE_FAST_BUY_POPUP](state, status) {
    state.fastBuyPopup = status;
  },
  [CHANGE_LOGIN_POPUP](state, status) {
    state.loginPopup = status;
  },
  [CHANGE_ADDRESSES_LIST_POPUP](state, status) {
    state.addressesListPopup = status;
  },
  [CHANGE_REGISTRATION_POPUP](state, status) {
    state.registrationPopup = status;
  },
  [CHANGE_RESET_POPUP](state, status) {
    state.resetPopup = status;
  },
  [CHANGE_RESTORE_POPUP](state, status) {
    state.restorePopup = status;
  },
  [CHANGE_INSTAGRAM_POPUP](state, status) {
    state.instagramPopup = status;
  },
  [CHANGE_SUCCESS_REGISTER_POPUP](state, data) {
    Object.assign(state.successRegisterPopup, data)
  },
  [CHANGE_ADD_ADDRESS_POPUP](state, status) {
    state.addAddressPopup = status;
  },
  [CHANGE_COSMETOLOG_POPUP](state, status) {
    state.anotherCosmetolog = status;
  },
  [CHANGE_APPLICATION_FORM_POPUP](state, status) {
    state.formApplicationPopup = status;
  },
  [CHANGE_PAYMENT_INFO_POPUP](state, status) {
    state.paymentInfoPopup = status;
  },
  [CHANGE_SHOW_SUCCESS_AGENT_REGISTER](state, status) {
    state.successRegisterAgent = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
