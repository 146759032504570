// import validation from '@/mixins/validation.js'
// import mainInput from '../../atoms/main-input/index.vue'
// import Vue from 'vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
// import {camelCase} from "lodash";
import {validationMixin} from "vuelidate";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";

export default {
  name: "registration",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        email: '',
        password: '',
        password_confirmation: '',
        type: 'private_buyer',
        name: '',
        second_name: '',
        professional_id: '',
        phone: '',
        work: ''
      },
      validationErrors: {},
      validationFiles: false,
      showPassword: false,
      rule: {
        require: true
      }
    }
  },
  validations: {
    payload: {
      phone: {
        required,
      },
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20),
      },
      password_confirmation: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20),
        sameAsPassword: sameAs('password')
      },
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(20)
      },
    }
  },
  components: {
    // mainInput
  },
  // mixins: [validation],
  computed: {
    ...mapGetters({
      loadingRegistration: 'auth/loadingRegistration'
    }),
    passwordErrors() {
      let error = [];
      if (!this.$v.payload.password.$dirty) {
        return error;
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 30));
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password.minLength) {

        error.push(this.$t('validationMin').replace(':count', 8));
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    passwordConfirmationErrors() {
      let error = [];
      if (!this.$v.payload.password_confirmation.$dirty) {
        return error;
      }
      if (!this.$v.payload.password_confirmation.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 30));
      }
      if (!this.$v.payload.password_confirmation.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password_confirmation.sameAsPassword) {
        error.push(this.$t('validationSameAs'));
      }
      if (!this.$v.payload.password_confirmation.minLength) {
        error.push(this.$t('validationMin').replace(':count', 8));
      }
      if (this.validationErrors.password_confirmation) {
        this.validationErrors.password_confirmation.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 60));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    phoneErrors() {
      let error = [];
      if (!this.$v.payload.phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.phone) {
        this.validationErrors.phone.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('validationMin').replace(':count', 3));
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 20));
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapMutations({
      changeRegistrationPopup: 'popups/CHANGE_REGISTRATION_POPUP',
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP',
      changeSuccessRegisterPopup: 'popups/CHANGE_SUCCESS_REGISTER_POPUP',
    }),
    ...mapActions({
      registrations: 'auth/REGISTRATION',
      fetchUser: 'profile/FETCH_USER_DATA'
    }),
    changePopup() {
      this.changeLoginPopup(true)
      this.changeRegistrationPopup(false)
    },
    removeAttr(ref) {
      this.$refs[`${ref}`].removeAttribute('readonly')
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.registrations(this.payload).then(() => {
          if (this.payload.professional_id.length) {
            this.fetchUser().then(() => {
              this.changeSuccessRegisterPopup({show: true, text: this.$t('successRegisterWithDiscount')})
              this.changeRegistrationPopup(false)
            })
          } else {
            this.fetchUser().then(() => {
              this.changeSuccessRegisterPopup({show: true, text: ''})
              this.changeRegistrationPopup(false)
              this.$router.push({name: 'profile'}).then(() => {
              }).catch(() => {
              })
            })
          }

        })
          .catch(error => {
            if (error.data.data && error.data.data.errors.professionalId) {
              this.$toasted.error(error.data.data.errors.professionalId);
            }
            if (error.data && error.data.errors.email) {
              this.$toasted.error(error.data.errors.email[0]);
            }
          });
      }
    },

  }
}
