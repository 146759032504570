import {$http} from '@/utils/https'
import {
  SET_PRODUCT,
  CHANGE_PRODUCT_LOADING,
  RESET_PRODUCT,
  SET_SAME_PRODUCTS,
  SET_SAME_PRODUCT
} from '../mutation-types';

import {
  GET_PRODUCT,GET_SAME_PRODUCTS, SEND_FEEDBACK,SEND_QUESTION,GET_SAME_PRODUCT
} from '../action-types';


const state = {
  product: null,
  productLoading: false,
  sameProducts: null,

};

const getters = {
  product: state => state.product,
  productLoading: state => state.productLoading,
  sameProducts: state => state.sameProducts,
};

const actions = {
  [GET_SAME_PRODUCT]: async ({commit}, payload) => {
    // commit(CHANGE_PRODUCT_LOADING, true);
    // commit(RESET_PRODUCT);
    try {
      const response = await $http.get(`v1/products/${payload.slug}/buy_with?include=categories,images,product_prices,unit,recommendations,faqs,feedbacks&category_slug=${payload.category}`);
      commit(SET_SAME_PRODUCT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(CHANGE_PRODUCT_LOADING, false);
    }
  },
  [GET_PRODUCT]: async ({commit}, payload) => {
    commit(CHANGE_PRODUCT_LOADING, true);
    commit(RESET_PRODUCT);
    try {
      const response = await $http.get(`v1/products/${payload.slug}?include=categories,leftover_type,images,product_prices,unit,recommendations,faqs,feedbacks&category_slug=${payload.category}`);
      commit(SET_PRODUCT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PRODUCT_LOADING, false);
    }
  },
  [SEND_FEEDBACK]: async ({commit}, payload) => {
    // commit(CHANGE_PRODUCT_LOADING, true);
    // commit(RESET_PRODUCT);
    try {
      const response = await $http.post(`v1/feedbacks`,payload);
      // commit(SET_PRODUCT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(CHANGE_PRODUCT_LOADING, false);
    }
  },
  [SEND_QUESTION]: async ({commit}, payload) => {
    // commit(CHANGE_PRODUCT_LOADING, true);
    // commit(RESET_PRODUCT);
    try {
      const response = await $http.post(`v1/products/faq?include=user`,payload);
      // commit(SET_PRODUCT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(CHANGE_PRODUCT_LOADING, false);
    }
  },
  [GET_SAME_PRODUCTS]: async ({commit}, payload) => {
    // commit(CHANGE_SAME_PRODUCTS_LOADING, true);
    try {
      const response = await $http.get(`v1/products/${payload}/buy_with?include=categories,images,product_prices,unit,recommendations,faqs,feedbacks`);
      commit(SET_SAME_PRODUCTS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      // commit(CHANGE_SAME_PRODUCTS_LOADING, false);
    }
  },
};

const mutations = {
  [SET_PRODUCT](state, obj) {
    state.product = null
    state.product = obj;
    // state.product.slider.data.unshift({
    //   id: Math.round(Math.random() * 100),
    //   link: state.product.image
    // })
  },
  [RESET_PRODUCT](state) {
    state.product = null;
  },
  [SET_SAME_PRODUCTS](state,list) {
    state.sameProducts = list;
  },
  [CHANGE_PRODUCT_LOADING](state, status) {
    state.productLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
