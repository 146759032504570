// import validation from '@/mixins/validation.js'
// import mainInput from '../../atoms/main-input/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
import {validationMixin} from "vuelidate";
import {email, maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
    name: "reset",
    mixin: [validationMixin],
    data() {
        return {
            payload: {
                email: '',
            },
            validationErrors: {},
        }
    },
    validations: {
        payload: {
            email: {
                required,
                maxLength: maxLength(60),
                email,
            },
        }
    },
    components: {
        // mainInput
    },
    // mixins: [validation],
    computed: {
        ...mapGetters({
            isLoading: 'auth/isLoading'
        }),
        emailErrors() {
            let error = [];
            if (!this.$v.payload.email.$dirty) {
                return error;
            }
            if (!this.$v.payload.email.maxLength) {
                error.push(this.$t('validationMax').replace(':count', 60));
            }
            if (!this.$v.payload.email.required) {
                error.push(this.$t('validationRequired'))
            }
            if (!this.$v.payload.email.email) {
                error.push(this.$t('validationEmail'))
            }
            if (this.validationErrors.email) {
                this.validationErrors.email.forEach((row) => {
                    error.push(row);
                });
            }
            return error;
        },
    },
    methods: {
        ...mapMutations({
            changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP',
            changeResetPopup: 'popups/CHANGE_RESET_POPUP',
        }),
        ...mapActions({
            resetPassword: 'auth/RESET_PASSWORD',
        }),
        submit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.resetPassword(this.payload).then(() => {
                    this.$toasted.success(this.$t('messageResetPassword'));
                    this.changeResetPopup(false)
                })
            }
        },
    }
}
