import {$http, } from '@/utils/https'
import {
  SET_PAGE,
  SET_MEDIA,
  SET_FAQ,
  CHANGE_PAGE_LOADING,
  SET_INGREDIENT,
  SET_NEWS,
  RESET_NEWS,
  SET_BEFORE_AFTER,
  CHANGE_REQUEST_LOADING,
  SET_COOPERATION_TERMS,
  SET_ONE_TERM
} from '../mutation-types';
import {
  GET_PAGE,
  GET_MEDIA,
  GET_FAQ,
  GET_INGREDIENT,
  GET_NEWS,
  GET_NEW,
  BEFORE_AFTER,
  USER_REQUESTS,
  COOPERATION_TERMS,
  GET_ONE_TERM

} from '../action-types';

const state  = {
  page: null,
  pageLoader: false,
  media: null,
  faq: null,
  pagesLoading:false,
  ingredient:null,
  news:null,
  beforeAfter:null,
  cooperationTerms:null,
  requestLoading: false,
}
const getters = {
  page: state => state.page,
  media: state => state.media,
  faq: state => state.faq,
  pagesLoading: state => state.pagesLoading,
  ingredient: state => state.ingredient,
  news: state => state.news,
  beforeAfter: state => state.beforeAfter,
  requestLoading: state => state.requestLoading,
  cooperationTerms: state => state.cooperationTerms,
}
const actions = {
  [USER_REQUESTS]: async ({commit},payload) => {
    commit(CHANGE_REQUEST_LOADING, true);
    try {
      const response = await $http.post(`v1/user_requests`,payload);
      // commit(SET_BEFORE_AFTER, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_REQUEST_LOADING, false);
    }
  },
  [BEFORE_AFTER]: async ({commit},payload) => {
    commit(CHANGE_PAGE_LOADING, true);
    try {
      const response = await $http.get(`v1/before_after`);
      commit(SET_BEFORE_AFTER, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PAGE_LOADING, false);
    }
  },
  [GET_ONE_TERM]: async ({commit},payload) => {
    commit(CHANGE_PAGE_LOADING, true);
    try {
      const response = await $http.get(`v1/cooperation_terms/${payload}`);
      commit(SET_PAGE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PAGE_LOADING, false);
    }
  },
  [COOPERATION_TERMS]: async ({commit},payload) => {
    commit(CHANGE_PAGE_LOADING, true);
    try {
      const response = await $http.get(`v1/cooperation_terms`);
      commit(SET_COOPERATION_TERMS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PAGE_LOADING, false);
    }
  },
  [GET_NEW]: async ({commit},payload) => {
    commit(CHANGE_PAGE_LOADING, true);
    commit(RESET_NEWS);
    try {
      const response = await $http.get(`v1/news/${payload}`);
      commit(SET_PAGE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PAGE_LOADING, false);
    }
  },
  [GET_NEWS]: async ({commit},payload) => {
    commit(CHANGE_PAGE_LOADING, true);
    try {
      const response = await $http.get(`v1/news?skip=&take=`);
      commit(SET_NEWS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PAGE_LOADING, false);
    }
  },
  [GET_INGREDIENT]: async ({commit},payload) => {
    commit(CHANGE_PAGE_LOADING, true);
    try {
      const response = await $http.get(`v1/innovative-ingredients/${payload}`);
      commit(SET_INGREDIENT, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PAGE_LOADING, false);
    }
  },
  [GET_PAGE]: async ({commit},payload) => {
    commit(CHANGE_PAGE_LOADING, true);
    try {
      const response = await $http.get(`v1/pages/${payload}?include=news,quality_certificates,patents,innovative_ingredients,catalog_and_protocols,partners`);
      commit(SET_PAGE, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PAGE_LOADING, false);
    }
  },
  [GET_MEDIA]: async ({commit}) => {
    commit(CHANGE_PAGE_LOADING, true);
    try {
      const response = await $http.get(`v1/media`);
      commit(SET_MEDIA, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PAGE_LOADING, false);
    }
  },
  [GET_FAQ]: async ({commit}) => {
    commit(CHANGE_PAGE_LOADING, true);
    try {
      const response = await $http.get(`v1/faqs`);
      commit(SET_FAQ, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_PAGE_LOADING, false);
    }
  },
}
const mutations = {
  [SET_PAGE](state,obj){
    state.page = obj
  },
  [RESET_NEWS](state,obj){
    state.news = null
  },
  [SET_MEDIA](state,obj){
    state.media = obj
  },
  [SET_FAQ](state,obj){
    state.faq = obj
  },
  [SET_INGREDIENT](state,obj){
    state.ingredient = obj
  },
  [SET_NEWS](state,obj){
    state.news = obj
  },
  [SET_BEFORE_AFTER](state,obj){
    state.beforeAfter = obj
  },
  [SET_COOPERATION_TERMS](state,obj){
    state.cooperationTerms = obj
  },
  [CHANGE_REQUEST_LOADING](state,status){
    state.requestLoading = status
  },
  [CHANGE_PAGE_LOADING](state,status){
    // setTimeout(()=>{
      state.pagesLoading = status
    // },1000)
  },
}
export default {
  state,
  getters,
  actions,
  mutations,
};
