import {mapActions, mapMutations} from "vuex";
import {snakeCase} from "lodash";

export default {
  name: "activate-another-cosmetolog",
  data() {
    return {
      payload:{
        professionalId: ''
      }
    }
  },
  created() {
  },
  computed: {},
  methods: {
    ...mapActions({
      updateCosmetologId:'profile/UPDATE_USER_DATA',
      fetchUser:'profile/FETCH_USER_DATA',
    }),
    ...mapMutations({
      changeAnotherCosmetologPopup:`popups/CHANGE_COSMETOLOG_POPUP`,
    }),
    submit(){
      let obj = {}
      for (let i in this.payload) {
        obj[snakeCase(i)] = this.payload[i]
      }
      this.updateCosmetologId(obj).then(()=>{
        this.fetchUser()
        this.$toasted.success(this.$t('successChangeCosmetolog'))
        this.changeAnotherCosmetologPopup(false)
      }).catch(()=>{
        this.$toasted.error('Не вірний код косметолога')
      })
    }
  }
}