import {$http} from '@/utils/https'
import {
  SEARCH_LIST_LOADING,
  SET_SEARCH_LIST,
} from '../mutation-types';
import {
  GET_SEARCH_LIST
} from '../action-types';


const state = {
  searchList: null,
  searchLoading: false,
};

const getters = {
  searchList: state => state.searchList,
  searchLoading: state => state.searchLoading,
};

const actions = {
  [GET_SEARCH_LIST]: async ({commit},payload) => {
    commit(SEARCH_LIST_LOADING, true);
    try {
      const response = await $http.get(`v1/search?query=${payload}&include=product_prices`);
      commit(SET_SEARCH_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(SEARCH_LIST_LOADING, false);
    }
  },
};

const mutations = {
  [SET_SEARCH_LIST](state, list) {
    state.searchList = list;
  },
  [SEARCH_LIST_LOADING](state, status) {
    state.searchLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
