import {mapGetters, mapMutations} from "vuex";

export default {
    name: "default-price",
    props: {
        product: {
            type: Object,
            default: () => {
            }
        }
    },
    data() {
        return {
            selectedCount: 1,
        }
    },
    computed: {
       ...mapGetters({
           basket: 'basket/basket',
           productsPdf: 'order/productsPdf',
       }),

    },
    methods: {
        ...mapMutations({
            increment: 'basket/INCREMENT_BASKET_LIST_COUNT',
            decrement: 'basket/DECREMENT_BASKET_LIST_COUNT',
            setCount: 'basket/SET_BASKET_LIST_COUNT',
            remove: 'basket/REMOVE_ITEM_FROM_BASKET',
        }),
        changeCount(type, count = 1) {
            if (type === 'increment') {
                this.increment(this.product)
            } else if (type === 'decrement') {
                this.decrement(this.product)
            } else {
                if (count) {
                    this.setCount({
                        ...this.product,
                        select_count: parseInt(count)
                    })
                }
            }
        },
    }
}
