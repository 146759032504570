import {$http} from '@/utils/https'
import {
  CHANGE_LOADING_LIST,
  SET_LIST,
  SET_CONTACTS, GET_FILTERS_LOADING, SET_FILTERS,
  SET_LIST_LENGTH, CHANGE_LOADING_BANNERS, SET_BANNERS,
  GET_NEW_PRODUCTS_LOADING,
  SET_NEW_PRODUCTS, SET_BESTSELLER, BESTSELLER_LOADING
} from '../mutation-types';
import {
  GET_CONTACTS, GET_FILTERS,
  GET_LIST, GET_BANNERS,
  GET_NEW_PRODUCTS,
  GET_BESTSELLER,
  LOGOUT,
  GET_INSTAGRAM_POSTERS
} from '../action-types';


const state = {
  list: [],
  listLoading: false,
  contacts: null,
  filters: [],
  filterLoading: false,
  bannerLoading: false,
  bannersList: null,
  newProducts: null,
  bestSellers: null,
  newProductLoading: false,
  bestSellerLoading: false
};

const getters = {
  contacts: state => state.contacts,
  list: state => state.list,
  listLoading: state => state.listLoading,
  filters: state => state.filters,
  filterLoading: state => state.filterLoading,
  bannerLoading: state => state.bannerLoading,
  bannersList: state => state.bannersList,
  newProducts: state => state.newProducts,
  bestSellers: state => state.bestSellers,
  newProductLoading: state => state.newProductLoading,
  bestSellerLoading: state => state.bestSellerLoading
};

const actions = {
  [GET_BANNERS]: async ({commit}) => {
    commit(CHANGE_LOADING_BANNERS, true);
    try {
      const response = await $http.get(`v1/banners`);
      commit(SET_BANNERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_BANNERS, false);
    }
  },
  [GET_LIST]: async ({commit}) => {
    commit(CHANGE_LOADING_LIST, true);
    try {
      const response = await $http.get(`v1/product?limit=${4}&main_page=true`);

      commit(SET_LIST, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_LIST, false);
    }
  },
  [GET_CONTACTS]: async ({commit}) => {
    try {
      const response = await $http.get(`v1/contact`);
      commit(SET_CONTACTS, response.data.data);
    } catch (e) {
      throw e;
    }
  },
  [GET_FILTERS]: async ({commit}) => {
    try {
      commit(GET_FILTERS_LOADING, true);
      const response = await $http.get(`v1/category?main_page=true`);
      commit(SET_FILTERS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(GET_FILTERS_LOADING, false);
    }
  },
  [GET_NEW_PRODUCTS]: async ({commit}) => {
    try {
      commit(GET_NEW_PRODUCTS_LOADING, true);
      const response = await $http.get(`v1/products/new?include=categories,images,product_prices,unit`);
      commit(SET_NEW_PRODUCTS, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(GET_NEW_PRODUCTS_LOADING, false);
    }
  },
  [GET_BESTSELLER]: async ({commit}) => {
    try {
      commit(BESTSELLER_LOADING, true);
      const response = await $http.get(`v1/products/bestseller?include=categories,images,product_prices`);
      commit(SET_BESTSELLER, response.data.data);
    } catch (e) {
      throw e;
    } finally {
      commit(BESTSELLER_LOADING, false);
    }
  },
  [GET_INSTAGRAM_POSTERS]: async ({commit}) => {
    try {
      // commit(BESTSELLER_LOADING, true);
      const response = await $http.get(`https://graph.instagram.com/me/media?fields=id,caption,media_url&access_token=${process.env.VUE_APP_INSTAGRAM_API_KEY}`);
      // commit(SET_BESTSELLER, response.data.data);
      console.log(response)
    } catch (e) {
      throw e;
    } finally {
      // commit(BESTSELLER_LOADING, false);
    }
  },
};

const mutations = {
  [SET_LIST](state, list) {
    state.list = list;
  },
  [SET_NEW_PRODUCTS](state, list) {
    state.newProducts = list;
  },
  [SET_BESTSELLER](state, list) {
    state.bestSellers = list;
  },
  [SET_BANNERS](state, list) {
    state.bannersList = list;
  },
  [CHANGE_LOADING_BANNERS](state, status) {
    state.bannerLoading = status;
  },
  [SET_CONTACTS](state, data) {
    state.contacts = data;
  },
  [CHANGE_LOADING_LIST](state, status) {
    state.listLoading = status;
  },
  [SET_LIST_LENGTH](state, count) {
    state.count = count;
  },
  [SET_FILTERS](state, list) {
    state.filters = list;
  },
  [GET_FILTERS_LOADING](state, status) {
    state.filterLoading = status;
  },
  [GET_NEW_PRODUCTS_LOADING](state, status) {
    state.newProductLoading = status
  },
  [BESTSELLER_LOADING](state, status) {
    state.bestSellerLoading = status
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
