// import validation from '@/mixins/validation.js'
// import mainInput from '../../atoms/main-input/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";
import {validationMixin} from "vuelidate";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";

export default {
  name: "restore",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        password: '',
        password_confirmation: '',
        token: this.$route.query.token,
        email: this.$route.query.email
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(30),
      },
      password_confirmation: {
        required,
        minLength: minLength(8),
        sameAsPassword: sameAs('password')
      }
    }
  },
  watch: {
    'payload.password': function () {
      this.$v.$reset()
    },
    'payload.password_confirmation': function () {
      this.$v.$reset()
    }
  },
  components: {},

  computed: {
    ...mapGetters({
      isLoading: 'auth/isLoading'
    }),
    passwordErrors() {
      let error = [];
      if (!this.$v.payload.password.$dirty) {
        return error;
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 30));
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password.minLength) {

        error.push(this.$t('validationMin').replace(':count', 8));
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    passwordConfirmationErrors() {
      let error = [];
      if (!this.$v.payload.password_confirmation.$dirty) {
        return error;
      }
      if (this.payload.password_confirmation.length > 30) {
        error.push(this.$t('validationMax').replace(':count', 30));
      }
      if (!this.$v.payload.password_confirmation.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password_confirmation.sameAsPassword) {
        error.push(this.$t('validationSameAs'));
      }
      if (!this.$v.payload.password_confirmation.minLength) {
        error.push(this.$t('validationMin').replace(':count', 8));
      }
      if (this.validationErrors.password_confirmation) {
        this.validationErrors.password_confirmation.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
  },
  methods: {
    ...mapMutations({
      changeRestorePopup: 'popups/CHANGE_RESTORE_POPUP',
    }),
    ...mapActions({
      setNewPassword: 'auth/SET_NEW_PASSWORD',
      login: 'auth/GET_TOKEN'
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.setNewPassword(this.payload).then(() => {
          let obj = {}
          obj.email = this.email
          obj.password = this.password
          this.login(this.payload).then(() => {
            this.$toasted.success(this.$t('successRestorePassword'));
            this.$router.replace({'query': null}).catch(() => {
              console.log('')
            })
            this.changeRestorePopup(false)
            window.location.reload()
          })
        })
      }
    },
  }
}
