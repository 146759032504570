import {validationMixin} from "vuelidate";
// import mainInput from '../../atoms/main-input/index.vue'
import {mapActions, mapGetters, mapMutations} from "vuex";

import {email, maxLength, minLength, required} from "vuelidate/lib/validators";

export default {
  name: "login",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        email: this.$route.query.email ? this.$route.query.email : '',
        password: ''
      },
      validationErrors: {},
      showPassword: false,
      emailRules: {
        require: true,
        email: true,
      },
      passwordRules: {
        minLength: 8,
        require: true
      },
      inputRefs: []
    }
  },
  validations: {
    payload: {
      email: {
        required,
        maxLength: maxLength(60),
        email,
      },
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(20),
      },
    }
  },
  components: {
    // mainInput
  },
  // mixins: [validation, validationMixin],
  computed: {
    ...mapGetters({
      loadingSingIn: 'auth/loadingSingIn'
    }),
    passwordErrors() {
      let error = [];
      if (!this.$v.payload.password.$dirty) {
        return error;
      }
      if (!this.$v.payload.password.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 30));
      }
      if (!this.$v.payload.password.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.password.minLength) {

        error.push(this.$t('validationMin').replace(':count', 8));
      }
      if (this.validationErrors.password) {
        this.validationErrors.password.forEach((row) => {
          error.push(row);
        })
      }
      return error;
    },
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 60));
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
  },
  methods: {
    ...mapMutations({
      changeRegistrationPopup: 'popups/CHANGE_REGISTRATION_POPUP',
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP',
      changeResetPopup: 'popups/CHANGE_RESET_POPUP',
    }),
    ...mapActions({
      login: 'auth/GET_TOKEN',
      fetchUser: 'profile/FETCH_USER_DATA',
      getFavorites: 'favorites/GET_FAVORITES_LIST',
      fetchHeaderMenu: 'setting/GET_HEADER_MENU',
    }),
    setItemRef(el) {
      if (el) {
        this.inputRefs.push(el)
      }
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.login(this.payload).then(() => {
          this.fetchUser()
          this.getFavorites()
          this.$toasted.success(this.$t('successLogin'));
          this.changeLoginPopup(false)
           this.$router.nav
          this.$router.push({name: 'profile'}).then(() => {

          }).catch(() => {})

          this.fetchHeaderMenu()

        }).catch((error) => {

          if (error.status === 418) {
            this.$toasted.error(this.$t('failLogin'));
          }
          if (error.status === 403) {
            this.$toasted.error(this.$t('notActivated'));
          }
          if (error.status === 401) {
            this.$toasted.error(this.$t('userNotFound'));
          }
          if (error.status === 422) {
            this.$toasted.error(this.$t('failLogin'));
            const errors = error.data.errors;
            for (const i in errors) {
              // Vue.set(this.validationErrors, camelCase(i), errors[i]);
              errors[i].forEach(e => {
                this.$toasted.error(e);
              })
            }
          }
        });
      }
    },
    changePopup() {
      this.changeLoginPopup(false)
      this.changeRegistrationPopup(true)
    },

  }
}
