import {maxLength, required, email, minLength} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "add-address",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        recipient_name: '',
        recipient_second_name: '',
        recipient_phone: '',
        country: '',
        city: '',
        street: '',
        building: '',
        flat: '',
        is_main: 0,
      },
      validationErrors: {},
    }
  },
  validations: {
    payload: {
      recipient_name: {
        required,
      },
      recipient_second_name: {
        required,
      },
      recipient_phone: {
        minLength: minLength(13),
        required,
      },
      country: {
        required,
      },
      city: {
        required,
      },
      street: {
        required,
      },
      building: {
        required,
      },
      flat: {
        required,
      },
    }
  },
  computed: {

    recipientNameErrors() {
      let error = [];
      if (!this.$v.payload.recipient_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.recipient_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.recipient_name) {
        this.validationErrors.recipient_name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    recipientSecondNameErrors() {
      let error = [];
      if (!this.$v.payload.recipient_second_name.$dirty) {
        return error;
      }
      if (!this.$v.payload.recipient_second_name.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.recipient_second_name) {
        this.validationErrors.recipient_second_name.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    recipientPhoneErrors() {
      let error = [];
      if (!this.$v.payload.recipient_phone.$dirty) {
        return error;
      }
      if (!this.$v.payload.recipient_phone.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.recipient_phone.minLength) {
        error.push(this.$t('validationMin').replace(':count', 13))
      }
      if (this.validationErrors.recipient_phone) {
        this.validationErrors.recipient_phone.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    countryErrors() {
      let error = [];
      if (!this.$v.payload.country.$dirty) {
        return error;
      }
      if (!this.$v.payload.country.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.country) {
        this.validationErrors.country.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    cityErrors() {
      let error = [];
      if (!this.$v.payload.city.$dirty) {
        return error;
      }
      if (!this.$v.payload.city.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.city) {
        this.validationErrors.city.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    streetErrors() {
      let error = [];
      if (!this.$v.payload.street.$dirty) {
        return error;
      }
      if (!this.$v.payload.street.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.street) {
        this.validationErrors.street.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    buildindErrors() {
      let error = [];
      if (!this.$v.payload.building.$dirty) {
        return error;
      }
      if (!this.$v.payload.building.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.building) {
        this.validationErrors.building.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    flatErrors() {
      let error = [];
      if (!this.$v.payload.flat.$dirty) {
        return error;
      }
      if (!this.$v.payload.flat.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.flat) {
        this.validationErrors.flat.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
  },
  methods: {
    ...mapMutations({
      changeAddressesListPopup: 'popups/CHANGE_ADDRESSES_LIST_POPUP',
      changeAddAddressPopup: `popups/CHANGE_ADD_ADDRESS_POPUP`,
      saveAddress: 'order/SAVE_SELECT_ADDRESS'
    }),
    ...mapActions({
      createAddress: 'profile/CREATE_ADDRESS',
      fetchUser: 'profile/FETCH_USER_DATA'
    }),
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.payload.status = 1
        this.saveAddress(this.payload)
        this.$toasted.success(this.$t('successAddNewAddress'));
        this.changeAddAddressPopup(false)
        this.changeAddressesListPopup(false)
        // this.fetchUser().then(() => {
        //
        // })
      }
    }
  }
}
