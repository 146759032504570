import {$httpInstagram} from '@/utils/https';


import {SET_CODE} from '../mutation-types';
import {
  GET_CODE
} from '../action-types';


const state = {
  authCode: ''
};

const getters = {
  authCode: state => state.authCode,
};

const actions = {
  [GET_CODE]: async ({commit}, payload) => {
    // commit(CHANGE_LOADING_SING_IN, true);
    try {
      const result = await $httpInstagram.get('authorize?client_id=1089452858439752&redirect_uri=https://alphascience.nwdev.net/&scope=user_profile,user_media&response_type=code');
      commit(SET_CODE, result);
    } catch (e) {
      throw e;
    } finally {
      // commit(CHANGE_LOADING_SING_IN, false)
    }
  },
};

const mutations = {
  [SET_CODE](state, data) {
    state.authCode = data
    localStorage.setItem('instagramAuth', 'true');
  }
};
export default {
  state,
  getters,
  actions,
  mutations,
};
