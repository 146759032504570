//instagram
export const SET_CODE = 'SET_CODE'

//auth
export const SET_TOKEN = 'SET_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const CHANGE_POPUP_RESTORE_PASSWORD = 'CHANGE_POPUP_RESTORE_PASSWORD';
export const CHANGE_POPUP_NEW_PASSWORD = 'CHANGE_POPUP_NEW_PASSWORD';
export const SET_LOADING_REGISTRATION = 'SET_LOADING_REGISTRATION';
export const CHANGE_POPUP_LDAP = 'CHANGE_POPUP_LDAP';
export const SING_IN_WITH_LDAP_LOADING = 'SING_IN_WITH_LDAP_LOADING';
export const CHECK_EMAIL_LOADING = 'CHECK_EMAIL_LOADING';
export const CHANGE_LOADING_SING_IN = 'CHANGE_LOADING_SING_IN';
export const SET_DEFAULT_PRICE_BASKET = 'SET_DEFAULT_PRICE_BASKET';
export const CHANGE_ADDRESS_COURIER = 'CHANGE_ADDRESS_COURIER';
export const CHANGE_PAYMENT_INFO_POPUP = 'CHANGE_PAYMENT_INFO_POPUP';
export const SET_COUNTRIES = 'SET_COUNTRIES'

export const SHOW_GLOBAL_LOADER = 'SHOW_GLOBAL_LOADER';
export const FIX_BODY = 'FIX_BODY';
//settings
export const SET_LANGUAGE_LIST = 'SET_LANGUAGE_LIST';
export const SET_TRANSLATIONS = 'SET_TRANSLATIONS';
export const SET_CATALOG_MENU_LOADING = 'SET_CATALOG_MENU_LOADING';
export const SET_CATALOG_MENU = 'SET_CATALOG_MENU';
export const CHANGE_SELECTED_CATEGOTY = 'CHANGE_SELECTED_CATEGOTY';
export const SET_LIST_CATEGORY_FILTERS = 'SET_LIST_CATEGORY_FILTERS';
export const SET_LIST_CATEGORY_FILTERS_LOADING = 'SET_LIST_CATEGORY_FILTERS_LOADING';
export const SET_VARIABLES = 'SET_VARIABLES';
export const SET_CERTIFICATE = 'SET_CERTIFICATE';
export const GET_CERTIFICATE_LOADING = 'GET_CERTIFICATE_LOADING';
export const CLEAR_CERTIFICATE = 'CLEAR_CERTIFICATE';
export const SET_SAME_PRODUCT = 'SET_SAME_PRODUCT';
//system
export const SET_CUSTOM_BREADCRUMBS_LIST = 'SET_CUSTOM_BREADCRUMBS_LIST';
export const RESET_CUSTOM_BREADCRUMBS_LIST = 'RESET_CUSTOM_BREADCRUMBS_LIST';
export const CHANGE_OPEN_MENU = 'CHANGE_OPEN_MENU';
export const CHANGE_OPEN_SECOND_MENU = 'CHANGE_OPEN_SECOND_MENU';
export const CHANGE_OPEN_FIRST_MENU = 'CHANGE_OPEN_FIRST_MENU';
export const CHANGE_OPEN_FILTER = 'CHANGE_OPEN_FILTER';
export const SET_PROPS_SECOND_MENU = 'SET_PROPS_SECOND_MENU';
//home
export const CHANGE_LOADING_BANNERS = 'CHANGE_LOADING_BANNERS';
export const SET_BANNERS = 'SET_BANNERS';
//basket
export const ADD_ITEM_TO_BASKET = 'ADD_ITEM_TO_BASKET';
export const REMOVE_ITEM_FROM_BASKET = 'REMOVE_ITEM_FROM_BASKET';
export const RESET_BASKET = 'RESET_BASKET';
export const CHECK_PROMO_CODE_LOADING = 'CHECK_PROMO_CODE_LOADING';
export const SET_BASKET_EDIT_COUNT = 'SET_BASKET_EDIT_COUNT';
export const REPLACE_ITEM_IS_BASKET = 'REPLACE_ITEM_IS_BASKET';
export const SET_DISCOUNT = 'SET_DISCOUNT';
export const RESET_DISCOUNT = 'RESET_DISCOUNT';
export const SET_PROMO_CODE_IN_BASKET = 'SET_PROMO_CODE_IN_BASKET';
export const SET_DATA_REMOVE_FROM_BASKET = 'SET_DATA_REMOVE_FROM_BASKET';
export const SHOW_POPUP_REMOVE_FROM_BASKET = 'SHOW_POPUP_REMOVE_FROM_BASKET';
export const INCREMENT_BASKET_LIST_COUNT = 'INCREMENT_BASKET_LIST_COUNT';
export const DECREMENT_BASKET_LIST_COUNT = 'DECREMENT_BASKET_LIST_COUNT';
export const SET_BASKET_LIST_COUNT = 'SET_BASKET_LIST_COUNT';
export const FRESH_BASKET_ITEMS = 'FRESH_BASKET_ITEMS';

export const SET_USER_DATA = 'SET_USER_DATA';
export const LOADING_USER_DATA = 'LOADING_USER_DATA';
export const CHANGE_LOADING_LIST = 'CHANGE_LOADING_LIST';
export const CHANGE_SKIP = 'CHANGE_SKIP';
export const SET_LIST_LENGTH = 'SET_LIST_LENGTH';
export const SET_LIST = 'SET_LIST';
export const GET_FILTERS_LOADING = 'GET_FILTERS_LOADING';
export const SET_FILTERS = 'SET_FILTERS';
export const REMOVE_FILTER_ITEM_IN_SEARCH = 'REMOVE_FILTER_ITEM_IN_SEARCH';
export const UPDATE_CHECKED_LIST_FILTER = 'UPDATE_CHECKED_LIST_FILTER';
export const CHANGE_LIMIT = 'CHANGE_LIMIT';
export const SET_SORT_TYPE = 'SET_SORT_TYPE';
export const SET_PRODUCT = 'SET_PRODUCT';
export const CHANGE_PRODUCT_LOADING = 'CHANGE_PRODUCT_LOADING';
export const RESET_PRODUCT = 'RESET_PRODUCT';
export const GET_ITEM_LOADING = 'GET_ITEM_LOADING';
export const SET_ITEM = 'SET_ITEM';
export const GET_LIST_LOADING = 'GET_LIST_LOADING';
export const UPDATE_USER_DATA_LOADING = 'UPDATE_USER_DATA_LOADING';
export const SHOW_POPUP_UPDATE_PASSWORD = 'SHOW_POPUP_UPDATE_PASSWORD';
export const UPDATE_USER_PASSWORD_LOADING = 'UPDATE_USER_PASSWORD_LOADING';
export const CHANGE_LOADING = 'CHANGE_LOADING';
export const ADD_ITEM_TO_LIST = 'ADD_ITEM_TO_LIST';
export const REPLACE_ITEM_FROM_LIST = 'REPLACE_ITEM_FROM_LIST';
export const REMOVE_ITEM_FROM_LIST = 'REMOVE_ITEM_FROM_LIST';
export const CHANGE_EDITED_ITEM = 'CHANGE_EDITED_ITEM';
export const CHANGE_STATUS_DIALOG_EDIT = 'CHANGE_STATUS_DIALOG_EDIT';
export const CHANGE_REMOVED_ITEM = 'CHANGE_REMOVED_ITEM';
export const CHANGE_STATUS_DIALOG_REMOVE = 'CHANGE_STATUS_DIALOG_REMOVE';
export const CHANGE_DIALOG_QUICK_ORDER = 'CHANGE_DIALOG_QUICK_ORDER';
export const QUICK_ORDER_LOADING = 'QUICK_ORDER_LOADING';
export const CHANGE_ADDRESS = 'CHANGE_ADDRESS';
export const RESET_ADDRESS = 'RESET_ADDRESS';
export const NOVA_POSHTA_GET_TOWNS_LOADING = 'NOVA_POSHTA_GET_TOWNS_LOADING';
export const NOVA_POSHTA_SET_TOWNS = 'NOVA_POSHTA_SET_TOWNS';
export const NOVA_POSHTA_GET_DEPARTMENS_LOADING = 'NOVA_POSHTA_GET_DEPARTMENS_LOADING';
export const NOVA_POSHTA_SET_DEPARTMENS = 'NOVA_POSHTA_SET_DEPARTMENS';
export const CHANGE_SUCCESS_BUY = 'CHANGE_SUCCESS_BUY';
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const CHANGE_LOADING_FILTERS = 'CHANGE_LOADING_FILTERS';
export const CHANGE_LOADING_PAGE = 'CHANGE_LOADING_PAGE';
export const SET_PAGE = 'SET_PAGE';
export const GET_NEW_PRODUCTS_LOADING = 'GET_NEW_PRODUCTS_LOADING';
export const SET_NEW_PRODUCTS = 'SET_NEW_PRODUCTS';
export const BESTSELLER_LOADING = 'BESTSELLER_LOADING';
export const SET_BESTSELLER = 'SET_BESTSELLER';
export const SET_SAME_PRODUCTS = 'SET_SAME_PRODUCTS';
export const SET_DELIVERY_METHODS = 'SET_DELIVERY_METHODS';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';
export const CHANGE_ADDRESSES_LIST_POPUP = 'CHANGE_ADDRESSES_LIST_POPUP';
export const SET_SYNC_BASKET = 'SET_SYNC_BASKET';
export const RESET_LIMIT = 'RESET_LIMIT';
export const CHANGE_INSTAGRAM_POPUP = 'CHANGE_INSTAGRAM_POPUP';
export const CHANGE_SUCCESS_REGISTER_POPUP = 'CHANGE_SUCCESS_REGISTER_POPUP';
export const CHANGE_ADD_ADDRESS_POPUP = 'CHANGE_ADD_ADDRESS_POPUP';
export const SET_CURRENT_ORDERS = 'SET_CURRENT_ORDERS';
export const SET_HISTORY_ORDERS = 'SET_HISTORY_ORDERS';
export const CHANGE_PAGE_LOADING = 'CHANGE_PAGE_LOADING';

export const CHANGE_USER_INFO = 'CHANGE_USER_INFO';
export const RESET_CHANGE_USER_INFO = 'RESET_CHANGE_USER_INFO';
export const CHANGE_DELIVERY_TYPE = 'CHANGE_DELIVERY_TYPE';
export const CHANGE_ADDRESS_LOADING = 'CHANGE_ADDRESS_LOADING';
export const SET_CONTACTS = 'SET_CONTACTS';
export const CHANGE_STATUS_FAVORITE = 'CHANGE_STATUS_FAVORITE';
export const CHANGE_CATEGORY_ID = 'CHANGE_CATEGORY_ID';
export const CHANGE_DEFAULT_PRICES = 'CHANGE_DEFAULT_PRICES';
export const SET_MEDIA = 'SET_MEDIA';
export const SET_FAQ = 'SET_FAQ';
export const SET_REPEAT_PRODUCTS = 'SET_REPEAT_PRODUCTS';
export const SET_SEARCH_LIST = 'SET_SEARCH_LIST';
export const SET_HEADER_MENU = 'SET_HEADER_MENU';
export const SET_INGREDIENT = 'SET_INGREDIENT';
export const SET_NEWS = 'SET_NEWS';
export const RESET_NEWS = 'RESET_NEWS';
export const SET_BEFORE_AFTER = 'SET_BEFORE_AFTER';
export const CHANGE_APPLICATION_FORM_POPUP = 'CHANGE_APPLICATION_FORM_POPUP';
export const CHANGE_REQUEST_LOADING = 'CHANGE_REQUEST_LOADING';
export const SET_COOPERATION_TERMS = 'SET_COOPERATION_TERMS';
export const SET_ONE_TERM = 'SET_ONE_TERM';
export const SET_DOWNOAD_PDF = 'SET_DOWNOAD_PDF';
export const CHANGE_DOWNLOAD_PDF_LOADING = 'CHANGE_DOWNLOAD_PDF_LOADING';
export const SEARCH_LIST_LOADING = 'SEARCH_LIST_LOADING';


// favourites
export const ADD_ITEM_TO_FAVOURITES = 'ADD_ITEM_TO_FAVOURITES';
export const ADD_PRODUCT_TO_FAVORITE_LOADING = 'ADD_PRODUCT_TO_FAVORITE_LOADING';
export const REMOVE_ITEM_FROM_FAVOURITES = 'REMOVE_ITEM_FROM_FAVOURITES';
export const REMOVE_PRODUCT_IN_FAVORITE_LOADING = 'REMOVE_PRODUCT_IN_FAVORITE_LOADING';
export const SET_FAVORITES_LIST = 'SET_FAVORITES_LIST';
export const SET_FAVOURITES_LIST_LENGTH = 'SET_FAVOURITES_LIST_LENGTH';
export const SET_FAVOURITES_LENGTH = 'SET_FAVOURITES_LENGTH';
export const SET_FAVORITES_LIST_LOADING = 'SET_FAVORITES_LIST_LOADING';
export const FAVOURITES_CHANGE_PAGE = 'FAVOURITES_CHANGE_PAGE';
export const SET_SELECT_FAVOURITES_ITEM = 'SET_SELECT_FAVOURITES_ITEM';
//payment
export const SET_INVOICE = 'SET_INVOICE';
export const CHANGE_INVOICE_LOADING = 'CHANGE_INVOICE_LOADING';
export const SET_TRANSACTION = 'SET_TRANSACTION';
export const CHANGE_TRANSACTION_LOADING = 'CHANGE_TRANSACTION_LOADING';
export const RESET_PAYMENT = 'RESET_PAYMENT';
export const SET_ORDER = 'SET_ORDER';

export const SET_AGENT_CODE = 'SET_AGENT_CODE';
export const SET_CLIENT_ORDER = 'SET_CLIENT_ORDER';

//POPUPS
export const CHANGE_LOGIN_POPUP = 'CHANGE_LOGIN_POPUP';
export const CHANGE_REGISTRATION_POPUP = 'CHANGE_REGISTRATION_POPUP';
export const CHANGE_RESET_POPUP = 'CHANGE_RESET_POPUP';
export const CHANGE_RESTORE_POPUP = 'CHANGE_RESTORE_POPUP';
export const CHANGE_COSMETOLOG_POPUP = 'CHANGE_COSMETOLOG_POPUP';
export const CHANGE_FAST_BUY_POPUP = 'CHANGE_FAST_BUY_POPUP';
export const CHANGE_SHOW_SUCCESS_AGENT_REGISTER = 'CHANGE_SHOW_SUCCESS_AGENT_REGISTER'


//orders
export const SET_ADDRESS_LIST = 'SET_ADDRESS_LIST'
export const SAVE_SELECT_ADDRESS = 'SAVE_SELECT_ADDRESS'
