<template>
  <div class="popup">
    <div class="popup-wrap">
      <div class="popup-bg" @click="closePopup()">
        <img v-if="showDefaultPrice" src="https://www.meme-arsenal.com/memes/e56b591cc0f62528334dd78d195ad2b0.jpg"
             alt="">
      </div>
      <div class="popup-container" :class="{'border':showDefaultPrice}" :style="{ 'max-width': maxWidth + 'px' }">
        <div class="popup-top" v-if="!instagramPopup">
          <div class="popup-top__title">
            {{ title }}
          </div>
          <button class="popup-close" @click="closePopup()">
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22.3827 6.76953L23.2311 7.61793L7.61793 23.2299L6.76953 22.3821L22.3827 6.76953Z"
                    fill="#4F4F4F"/>
              <path d="M7.61793 6.76953L23.2311 22.3815L22.3827 23.2305L6.76953 7.61853L7.61793 6.76953Z"
                    fill="#4F4F4F"/>
            </svg>
          </button>
        </div>
        <div class="popup-content">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'popup-wrap',
  props: {
    title: {},
    maxWidth: {
      type: Number,
      default: 840
    },
    backgroundHide: {
      type: Boolean,
      default: () => false
    }
  },
  created() {
    document.getElementById('html').classList.add('hide')
  },
  computed: {
    ...mapGetters({
      instagramPopup: 'popups/instagramPopup',
      showDefaultPrice: 'popups/showDefaultPrice',
    })
  },
  methods: {
    closePopup() {
      this.$emit('close-popup');
      document.getElementById('html').classList.remove('hide')
    }
  }
}
</script>
<style lang="scss">
@import "../../assets/scss/vars";


.popup {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 50;
  overflow-y: auto;



  &-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);

    img {
      width: 100%;
      height: 100%;
      filter: blur(3px);
      opacity: .96;
    }
  }

  &-wrap {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 10;
    overflow-y: auto;
  }

  &-container {

    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    max-width: 840px;
    background: #fff;
    padding: 25px 32px;
    position: relative;
    z-index: 2;

    &.border {
      border: 1px solid #F2F2F2;
    }
  }

  &-content {

  }

  &-top {
    width: 100%;
    padding-bottom: 15px;
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &__title {
      width: 100%;
      max-width: 100%;
      text-align: center;
      margin-top: 35px;
      font-weight: 300;
      font-size: 24px;
      line-height: 29px;
      color: #000000;

    }
  }

  &-close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    width: 30px;
    height: 30px;

    svg {
      width: 30px;
      height: 30px;
      //color: #9a9a9a;
    }
  }
}

@media screen and (max-width: 900px) {
  .popup-bg {
    position: fixed;
  }
  .popup-wrap {
    padding-left: 15px;
    padding-right: 15px;

  }
  .popup-wrap {

  }
}

@media screen and (max-width: 425px) {
  .popup-container {
    padding: 15px 20px;
  }
  .popup-top__title {
    font-size: 16px;
  }
  .popup-top {
    padding-bottom: 10px;
  }
}
</style>

